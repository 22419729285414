@import "../../styles/typography.scss";
@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";

.session-timed-out-dialog {
  .MuiPaper-root {
    overflow: hidden;
    width: 690px;
    border-radius: 24px;
    padding: 32px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;

      .MuiSvgIcon-root {
        color: $LightOutline;
        width: 38px;
        height: 38px;
      }

      h4 {
        @include headline-medium;
        margin: 0;
      }
    }

    p {
      @include body-large;

      span {
        font-weight: 600;
      }
    }

    .dialog-box-buttons {
      @include flexbox(flex-end);
      gap: 10px;
      margin-top: 22px;

      .MuiButtonBase-root {
        &:first-child {
          @include outlined-button;
        }

        &:last-child {
          @include blue-contained-button;
        }
      }
    }
  }
}
