@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.info-tile {
  width: 298px;
  @include flexbox;
  flex-direction: column;
  p {
    margin-bottom: 4px;
    &:first-child {
      @include label-medium;
    }
    @include body-medium;
  }
  .MuiInputBase-root {
    width: 164px;
    height: 28px;
    border-radius: 4px !important;
    background-color: #fff;
    font-size: 12px;
    .MuiInputAdornment-root {
      margin-right: 4px;
      .MuiTypography-root {
        margin: 0;
        margin-top: 2px;
      }
    }
  }
  .MuiAutocomplete-inputRoot {
    -webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .MuiFormGroup-root {
    margin-left: 12px;
    .MuiButtonBase-root {
      padding: 0 2px;
    }
    .MuiTypography-root {
      @include body-medium;
      font-size: 14px;
    }
  }
  &.error {
    p {
      color: $LightError;
    }
  }
}
