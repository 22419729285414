@import '../../../styles/mixins.scss';
@import '../../../styles/palette.scss';
@import '../../../styles/typography.scss';

.Notes {
  .MuiInputBase-root {
    padding: 10px;
    border-radius: 12px;
    font-size: 14px;
    width: 1208px;
    min-height: 84px;
    background-color: white;
    .MuiInputBase-input {
      margin-bottom: auto;
    }
    textarea::-webkit-scrollbar {
      display: none;
    }
    .MuiInputAdornment-root {
      margin-top: auto;
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  .note-length {
    @include body-small;
    text-align: right;
    margin-left: auto;
    margin-top: 2px;
    margin-right: 24px;
    margin-bottom: 8px;
  }
  .note-field {
    margin-top: 12px;
  }
  .note-list {
    .loading-note {
      @include flexbox(center, center);
      font-size: 60px;
      color: $BlueSteel;
      margin-bottom: 1.5rem;
    }
    .edit-note {
      background-color: white;
      padding: 12px 16px;
      border: 2px solid $InitioBlue;
      border-radius: 12px;
      width: 880px;
      * {
        border: none !important;
        box-shadow: none;
      }
      .MuiInputBase-root {
        padding: 0;
        height: 80px;
        width: 840px;
        &:hover {
          * {
            box-shadow: none !important;
          }
        }
      }
      .edit-note-btns {
        margin-top: 5px;
        @include flexbox(flex-end);
        gap: 10px;
        .edit-note-cancel-btn {
          @include outlined-button;
          border: 1px solid #315880 !important;
          height: 24px;
        }
        .edit-note-save-btn {
          @include blue-contained-button;
          height: 24px;
        }
      }
    }
    .note {
      position: relative;
      @include flexbox;
      flex-direction: column;
      margin-right: auto;
      margin-bottom: 12px;
      border-radius: 12px;
      padding: 16px 12px;
      &:first-child {
        margin-top: 12px;
      }
      &.isEditing {
        position: relative;
        background: rgba(0, 72, 132, 0.08);
        * {
          visibility: hidden;
        }
        .loading-note {
          * {
            visibility: visible;
          }
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 24px;
          z-index: 999;
          @include flexbox(center, center);
          .loading-spinner {
            $spinnerSize: 24px;
            height: $spinnerSize;
            width: $spinnerSize;
            @include spin-infinitely();
          }
        }
      }
      &:hover {
        background: rgba(0, 72, 132, 0.08);
      }
      .note-date-time {
        .note-author {
          @include label-large;
        }
        align-items: center;
        @include body-small;
        .note-delete {
          @include flexbox(flex-end);
          .MuiButtonBase-root {
            padding: 0px;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
              background-color: white;
            }
          }
        }
      }
      .note-content {
        @include body-medium;
        word-wrap: normal;
      }
      .note-vert-icon {
        position: absolute;
        color: #999ba5;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 2px;
        right: 8px;
        top: 8px;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
          box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85) !important;
        }
        &:focus-visible {
          background-color: rgba(0, 97, 164, 0.1);
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
  }
}

.note-menu {
  .MuiButtonBase-root {
    @include body-medium;
    @include flexbox;
    gap: 10px;
  }
}
