@import './palette';
@import './typography';

@mixin flexbox($justifyContent: flex-start, $alignItems: flex-start, $flexDirection: row) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $flexDirection;
}

@mixin white-container($containerWidth) {
  background-color: $Container2;
  width: $containerWidth;
  box-shadow: 0 8px 16px rgb(0 49 93 / 20%);
  border-radius: 28px;
  padding: 40px 48px;
}

@mixin backdrop-container($containerWidth) {
  width: $containerWidth;
  box-shadow: 0 8px 16px rgb(0 49 93 / 20%);
  border-radius: 8px 8px 0px 0px;
  padding: 40px 2.88rem;
}

@mixin button-to-link {
  border: none;
  text-transform: none;
  text-decoration: underline;
  background: none !important;
  padding: 0;
  color: $InitioBlue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:disabled {
    color: inherit;
  }
}

@mixin default-buttons-style {
  svg {
    margin-right: 10px;
  }
  @include label-large;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  &:active {
    outline: none;
  }
}

@mixin blue-contained-button {
  @include default-buttons-style;
  background-color: $Primary50;
  color: #fff;
  border: 1px solid $Primary50;
  &:hover {
    background-color: $Primary30;
    border: 1px solid $Primary30;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    border: 1px solid $Primary30;
    background-color: $Primary30;
  }
  &:active {
    background-color: $Primary30;
    border: 1px solid $Primary30;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #fff;
    background-color: $Outline;
    border: 1px solid $Outline;
  }
}

@mixin blue-contained-button-dark {
  @include default-buttons-style;
  background-color: $Primary50;
  border: 1px solid $Primary50;
  color: #fff;
  &:hover {
    color: $DarkOnPrimary;
    background-color: $Primary80;
    border: 1px solid $Primary80;
  }
  &:focus-visible {
    color: $DarkOnPrimary;
    outline: 2px solid $InitioBlue;
    background-color: $Primary80;
    border: 1px solid $Primary80;
  }
  &:active {
    color: $DarkOnPrimary;
    background-color: $Primary70;
    border: 1px solid $Primary70;
  }
  &:disabled {
    color: $DarkOnSurface;
    background-color: rgba(227, 227, 227, 0.24);
    border: 1px solid rgba(227, 227, 227, 0.24);
  }
}

@mixin tonal-contained-button {
  @include default-buttons-style;
  background-color: #d0d9ee;
  color: $BlueSteel;
  &:hover {
    background-color: #c3d4ee;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    background-color: #bbcbe4;
  }
  &:active {
    background-color: #bac2d5;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #303d5c;
    opacity: 0.8;
    background-color: $Outline;
  }
}

@mixin red-contained-button {
  @include default-buttons-style;
  background-color: $LightError;
  border: 1px solid $LightError;
  color: #fff;
  border: 1px solid $LightError;
  &:hover {
    background-color: $Error30;
    border: 1px solid $Error30;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    border: 1px solid $Error30;
    background-color: $Error30;
  }
  &:active {
    border: 1px solid $Error20;
    background-color: $Error20;
    border: 1px solid $Error20;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #fff;
    border: 1px solid $Outline;
    background-color: $Outline;
    border: 1px solid $Outline;
  }
}

@mixin light-filled-button($box-shadow: true) {
  @include default-buttons-style;
  background-color: $LightSurface;
  color: $Primary40;
  @if $box-shadow {
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:hover {
    background: rgb(0 72 132 / 8%);
    @if $box-shadow {
      box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
    }
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    background: rgb(0 72 132 / 12%);
  }
  &:active {
    background: rgb(0 28 57 / 10%);
    @if $box-shadow {
      box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
    }
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    background-color: $Outline;
  }
}

@mixin outlined-button {
  @include default-buttons-style;
  background: none;
  border: 1px solid #315880;
  color: $Primary40;
  &:hover {
    background-color: rgb(0 72 132 / 8%);
  }
  &:focus-visible {
    background-color: rgb(0 72 132 / 8%);
    border: 1px solid $InitioBlue;
    outline: 1px solid $InitioBlue;
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    border: 1px solid $Outline;
  }
}

@mixin outlined-button-dark {
  @include default-buttons-style;
  background: none;
  border: 2px solid $DarkOutline;
  color: #fff;
  &:hover {
    background-color: rgba(158, 202, 255, 0.12);
  }
  &:focus-visible {
    background-color: rgba(158, 202, 255, 0.16);
    border: 2px solid #fff;
  }
  &:active {
    color: $Primary80;
    background-color: rgba(158, 202, 255, 0.08);
    box-shadow: 0px 4px 8px 0px rgba(18, 29, 33, 0.12) inset;
  }
  &:disabled {
    color: rgba(234, 234, 237, 0.3);
    border: 2px solid rgba(234, 234, 237, 0.3);
  }
}

@mixin text-only-button($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary40;
  @if $textDecoration != none {
    color: $InitioBlue;
  }
  &:hover {
    background-color: rgb(0 72 132 / 8%);
    text-decoration: $textDecoration;
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    background-color: rgb(0 72 132 / 8%);
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin text-only-button-dark($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary80;
  &:hover {
    background-color: rgb(158 202 255 / 8%);
  }
  &:focus-visible {
    outline: 2px solid $InitioBlue;
    background-color: rgb(158 202 255 / 8%);
  }
  &:active {
    background-color: rgb(158 202 255 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin dropdown-scrollbar() {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 12px;
  }
}

@mixin select-dropdown() {
  @include dropdown-scrollbar;
  border-radius: 12px;
  .MuiList-root {
    background-color: $Container2;
  }
  .MuiButtonBase-root {
    width: 100%;
    min-height: 32px;
    text-align: left;
    gap: 18px;
    &[aria-selected='true'] {
      background-color: rgb(66 149 243 / 8%);
      box-shadow: 4px 0 0 $InitioBlue inset;
    }
    &:hover,
    &.Mui-focusVisible {
      background-color: rgb(66 149 243 / 8%);
    }
    &.Mui-focusVisible {
      box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset, 0 -2px 0 $InitioBlue inset,
        -2px 0 0 $InitioBlue inset;
    }
  }
}

@mixin autocomplete-dropdown() {
  border-radius: 12px;
  .MuiAutocomplete-listbox {
    @include dropdown-scrollbar;
    background-color: $Container1;
    .MuiListSubheader-root {
      @include label-large;
      background-color: $Container1;
      height: 32px;
      padding: 6px 16px;
    }
    .MuiAutocomplete-groupUl {
      background-color: $Container2;

      .MuiAutocomplete-option {
        @include body-medium;
        width: 100%;
        height: 32px;
        text-align: left;
        padding: 6px 16px;
        &[aria-selected='true'] {
          background-color: rgb(66 149 243 / 8%);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
        &:hover,
        &.Mui-focusVisible {
          background-color: rgb(66 149 243 / 8%);
        }
        &.Mui-focusVisible {
          box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset,
            0 -2px 0 $InitioBlue inset, -2px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}

@mixin spin-infinitely() {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: spin 4s infinite linear;
}

@mixin all-browsers-transform($numDegrees) {
  -webkit-transform: rotate($numDegrees);
  -moz-transform: rotate($numDegrees);
  -ms-transform: rotate($numDegrees);
  -o-transform: rotate($numDegrees);
  transform: rotate($numDegrees);
}

@mixin spaced-between-flexbox(
  $justifyContent: space-between,
  $flexWrap: nowrap,
  $flexDirection: row
) {
  display: flex;
  flex-direction: $flexDirection;
  flex-wrap: $flexWrap;
  justify-content: $justifyContent;
}

@mixin spinning-dots {
  @-webkit-keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  @keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em,
        -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em,
        -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em,
        1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em,
        -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em,
        -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em,
        1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em,
        -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em,
        -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em,
        1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em,
        -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em,
        -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em,
        1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em,
        -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em,
        -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em,
        1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em,
        -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em,
        -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em,
        1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em,
        -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em,
        -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em,
        1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
        -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
        -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
        1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  .dots-circle-spinner {
    display: inline-block;
    height: 1em;
    width: 1em;
    line-height: 1;
    vertical-align: middle;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    transform: scale(0);
    opacity: 0;
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em,
      -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em,
      -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em,
      1.41421356em -1.41421356em 0 -0.0625em;
    transform: scale(0.25);
    opacity: 1;
    -webkit-animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
    animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
  }
}
