@import '../../../../styles/palette.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.SummariesTable {
  border: 1px solid $Outline;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @include flexbox();
  .fixed-left {
    .summaries-table-rows {
      position: absolute;
      width: 336px;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);
      .table-cell {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .right-scrollable {
    .loading-overlay {
      width: 976px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(240, 240, 243, 0.75);
      @include flexbox(center, center);
      .MuiButtonBase-root {
        @include blue-contained-button;
        &.loading {
          pointer-events: none;
        }
        &.complete {
          background-color: $Success;
          pointer-events: none;
          border: 1px solid $Success;
        }
      }
    }
    .summaries-table-rows {
      margin-left: 336px;
      width: 976px;
      overflow: scroll;
    }
  }
  .summaries-table-rows {
    height: 346px;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    .summaries-table-row-data {
      @include flexbox(flex-start, center);
      border-bottom: 1px solid $Outline;
      .row-data-group {
        background-color: $Background1;
      }
      &.inactive-summaries-row {
        .row-data-group {
          background-color: $LightErrorContainer;
        }
      }
      .row-data-group {
        @include flexbox;
        .table-cell {
          height: 32px;
          @include body-small;
          @include flexbox(center, center);
          &:last-child {
            margin-right: 16px;
          }
        }
        &.summaries-list {
          .table-cell {
            &:nth-child(1) {
              @include flexbox(flex-start, center);
              @include label-medium;
              padding-left: 24px;
              min-width: 336px;
            }
          }
        }
      }
    }
  }
  .fixed-left {
    .summaries-table-rows {
      .summaries-table-row-data {
        .table-cell {
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
