@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.SuccessfulIntake {
  @include flexbox(flex-start, center, column);
  height: 600px;
  .dots-circle-spinner {
    @include flexbox(center, center);
    color: $DarkOutline;
    position: relative;
    font-size: 5rem;
    top: 30rem
  }
  .header-info {
    @include headline-5();
    @include flexbox(flex-start, flex-start);
    width: 73.5rem;
    margin-top: 2rem;
    p {
      margin-bottom: 1.5rem;
      &:first-child {
        color: $Primary40;
      }
      &:nth-child(2) {
        margin-left: 1.5rem;
      }
    }
  }

  .success-content {
    @include flexbox(flex-start, center, column);
    @include white-container(74.0625rem);
    h2 {
      @include headline-large(Manrope);
    }
    .img-wrapper {
      margin-top: 1rem;
      @include flexbox(center);
      .success-checkmark {
        width: 13.3125rem;
        height: 11.25rem;
      }
    }
    .success-msg-container {
      margin: 0.3125rem 0rem;
      width: 100%;
      @include flexbox(center);
      gap: 0.625rem;
      background: $BackgroundGreen;
      border-radius: 0.25rem;
      p {
        @include body-copy('Noto Sans');
        color: $Success50;
        width: 48.875rem;
        padding: 1.5rem 3rem 1.5rem 4rem;
        margin: 0;
      }
      .next-steps {
        @include body-copy('Noto Sans');
        color: $Success50;
        margin: 0;
      }
    }
  }

  .MuiButtonBase-root {
    @include button-to-link;
    margin-top: 1.8rem;
  }
}
