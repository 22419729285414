@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.EquityInputs {
  h6 {
    @include label-large;
    margin: 0;
  }
  .vl {
    height: 88px;
    margin: 0 4px;
  }
  p {
    @include body-small;
    margin: 0;
  }
  .value-block {
    height: 88px;
    width: 172px;
    margin: 0 2px;
    @include flexbox(center, center, column);
    background-color: #42a179;
    border-radius: 8px;
    span {
      text-align: center;
      @include body-small;
      color: $Background1;
      &:first-child {
        @include label-large;
        color: #fff;
        font-size: 16px;
      }
    }
    &.awaiting-input {
      background-color: rgba(136, 221, 198, 0.5);
      span {
        @include body-small;
        color: #7d8b9b;
      }
    }
  }
  .header-row {
    .value-block {
      &:last-child {
        background-color: $BlueSteel;
      }
    }
  }
  .header-row {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $LightSurfaceVariant;
    margin-top: 24px;
    padding: 16px 12px;
    @include flexbox(flex-start, center);
    gap: 12px;
    .vl {
      border-right: 1px solid $LightOutline;
    }
  }
  .run-calc-tip {
    @include flexbox(flex-start, center);
    @include body-small;
    background-color: $Tertiary95;
    border-radius: .25rem;
    padding: 1rem 1.5rem;
    .MuiSvgIcon-root {
      color: $Tertiary30;
      margin-right: 0.5rem;
      font-size: 1.25rem;
    }
    p {
      @include body-medium;
      font-size: 14px;
      color: $Tertiary30;
    }
    .run-calc-btn {
      @include text-only-button;
      color: $Primary40;
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: none;
      margin-left: auto;
      .MuiSvgIcon-root  {
        color: $Primary40;
      }
    }
    .close-tip-btn {
      @include text-only-button;
      min-width: 0.5rem;
      padding: 0.625rem 0.75rem;
      margin-left: 1rem;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
        margin: 0px;
      }
    }
  }
  .equity-inputs-rows {
    background-color: $Container2;
    padding: 16px 12px;
    .equity-row-title,
    .footnote-title {
      @include flexbox(flex-start, center);
      gap: 8px;
      .MuiButtonBase-root {
        @include text-only-button;
      }
    }
    .inputs-row {
      @include flexbox(flex-start, center);
      .vl {
        border-right: 1px solid $Outline;
        margin-right: 24px;
      }
      .MuiFormControl-root {
        margin-right: 24px;
        &:nth-child(1) {
          width: 272px;
        }
        &:nth-child(2) {
          width: 120px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 96px;
        }
      }
      .value-block {
        margin-right: 24px;
        &:last-of-type {
          margin-right: 12px;
        }
      }
      .MuiIconButton-root {
        color: $Primary40;
      }
    }
    .footnote {
      margin-top: 16px;
      padding: 16px;
      background-color: $NeutralVariant95;
      border-radius: 8px;
      .MuiFormControl-root {
        width: 100%;
        margin-top: 16px;
        .MuiInputBase-root {
          background-color: #fff;
        }
      }
      .footnote-characters-left {
        @include body-small;
        width: fit-content;
        margin-right: 8px;
        margin-left: auto;
      }
    }
  }
}

.fiscal-period {
  .hidden {
    display: none;
  }
}
