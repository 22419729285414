@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.InvestorIntake {
  margin: auto;
  .page-header {
    width: 1200px;
    margin: 48px auto 24px;

    .header-nav {
      @include flexbox(flex-start, center);
      @include title-large();

      .MuiButtonBase-root {
        @include outlined-button;
        margin-left: 24px;
        height: 40px;
        white-space: nowrap;
      }

      p {
        font-weight: 400;
        margin: 0;
        &:first-child {
          color: $Primary40;
        }

        &:nth-child(2) {
          margin-left: 24px;
        }
      }
      margin-bottom: 16px;
    }

    .copy-btn-wrapper {
      display: flex;
      
      .MuiButtonBase-root {
        &:first-of-type {
          margin-left: 48px;
        }
        margin-left: 16px;
        @include blue-contained-button;
        height: 40px;
        
        &.save-btn,
        &.back-btn,
        &.add-company-btn,
        &.schedule-upload-btn {
          @include outlined-button;
          white-space: nowrap;
        }
        
        &.save-btn {
          .loading-spinner {
            filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%)
            hue-rotate(207deg) brightness(108%) contrast(110%);
            margin-right: 8px;
            height: 16px;
            width: 16px;
            @include spin-infinitely();
          }
        }
      }
    }
      
      .header-text {
      @include body-medium;
      white-space: nowrap;
      p {
        margin: 0;
      }
      margin-bottom: 16px;
    }
  }

  .company-list {
    @include flexbox(flex-start, center, column);
    gap: 8px;
    margin-bottom: 48px;

    .company-btn {
      width: 1246px;
      border-radius: 28px;
      border: 1px solid $Outline;
      overflow: hidden;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.2);

      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }

      .company-btn-head-content {
        background-color: $Background1;

        &:hover {
          cursor: pointer;
        }

        &:hover,
        &.is-open {
          background-color: #d8e2eb;
        }

        &:active {
          background-color: #c0d3e3;
        }

        object-fit: cover;
        height: 64px;
        padding: 16px 24px;
        @include flexbox(space-between, center);

        .left-btn-side,
        .right-btn-side {
          @include flexbox(center, center);
        }

        span {
          @include body-large('Manrope');
          font-weight: 600;
        }

        .right-btn-side {
          span {
            @include label-large;
          }

          span,
          .rotating-chevron {
            color: $Primary40;
          }

          .rotating-chevron {
            &.upward {
              transition: transform 0.5s linear;
              @include all-browsers-transform(-180deg);
            }

            &.downward {
              transition: transform 0.5s linear;
              @include all-browsers-transform(0deg);
            }
          }
        }

        .number-icon,
        .done-icon {
          padding: 0;
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          border: 2px solid $LightOnSurfaceVariant;
          @include body-small;
          @include flexbox(center, center);
        }

        .done-icon {
          border: none;
          background-color: $InitioBlue;

          .MuiSvgIcon-root {
            fill: #ffffff;
            padding: 3px;
          }
        }

        .right-chevron-btn {
          margin-left: 32px;
        }

        .right-chevron-btn,
        .right-handle-btn {
          height: 32px;
          width: 32px;
          background: none;
          border: none;
          cursor: grab;
          @include flexbox(center, center);
          border-radius: 50%;

          &:hover {
            background-color: #e6f0f6;
            cursor: pointer;
          }

          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }

          &:active {
            background-color: #99c0db;
          }
        }
      }
    }
  }

  .fetching-investor-companies {
    @include flexbox(center, center);
    font-size: 48px;
    color: $LightOnSurfaceVariant;
  }

  .fetching-tr-data {
    margin: 0 8px 0 40px;
    color: $LightOnSurfaceVariant;
  }
}

.upload-block {
  background-color: $NeutralVariant95;
  margin-left: -48px;
  margin-right: -48px;
  padding: 24px 48px;
  margin-top: 32px;
  &.secondary-transactions {
    margin-top: 0;
    padding-top: 0;
    .file-upload-container {
      margin-top: 0;
    }
  }
  .upload-block-header {
    @include flexbox();
    gap: 12px;
    h4 {
      margin-bottom: 8px !important;
    }
    p, ul {
      @include body-large;
      &.bold {
        font-weight: 500 !important;
      }
      ul {
        list-style-type: disc;
      }
    }
    .MuiSvgIcon-root {
      color: $NeutralVariant30;
      height: 54px;
      width: 54px;
    }
  }
  .file-upload-container {
    @include flexbox();
    margin-top: 24px;
    .drag-and-drop-area {
      position: relative;
      border: 2px dashed $NeutralVariant70;
      border-radius: 24px;
      @include flexbox(center, center);
      flex-direction: column;
      width: 50%;
      height: 200px;
      background-color: #dae4f1;
      .file-upload-loading-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 24px;
        z-index: 999;
        background: #fafafd;
        opacity: 0.8;
        @include flexbox(center, center);
        .loading-spinner {
          $spinnerSize: 62px;
          height: $spinnerSize;
          width: $spinnerSize;
          @include spin-infinitely();
        }
      }
      &.file-dragging {
        cursor: default !important;
        border: 2px dashed #0967a7;
        background-color: #f3f5fa;
      }
      .browse-or-drop {
        @include flexbox(center, center, column);
        span {
          @include body-large;
        }
        .MuiSvgIcon-root {
          color: $LightOnSurfaceVariant;
          height: 40px;
          width: 40px;
        }
        .MuiButtonBase-root {
          @include tonal-contained-button;
        }
      }
      .file-style {
        display: none;
      }
    }
    .uploaded-files {
      width: 50%;
      .file {
        @include flexbox(flex-start, center);
        padding: 16px 20px;
        background-color: $LightPrimaryContainer;
        border-radius: 24px;
        margin-left: 15px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.25px;
        &.completed {
          background-color: #ecf9f6;
          .check-icon {
            color: $Success50;
          }
        }
        .file-name {
          @include flexbox(flex-start, center);
          gap: 8px;
          @include body-medium;
        }
        .clear-icon {
          color: #999ba5;
          border-radius: 50%;
          height: 32px;
          width: 32px;
          padding: 2px;
          margin-left: auto;
          &:hover {
            background-color: rgba(0, 97, 164, 0.1);
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &:active {
            background-color: rgba(0, 97, 164, 0.4);
          }
        }
      }
      .MuiLinearProgress-root {
        width: 260px;
        margin: 12px 0px 2px;
        height: 16px;
        border-radius: 16px;
        outline: 1px solid $InitioBlue;
        background-color: #fff;
        .MuiLinearProgress-bar {
          background-color: $InitioBlue;
        }
      }
    }
  }
}

.schedule-upload-dialog {
  .MuiPaper-root {
    padding: 40px 0;
    border-radius: 12px;
    min-width: 880px;
    .close-icon {
      position: absolute;
      right: 24px;
      top: 24px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .text-and-icon {
      @include flexbox(center, center);
      margin: 0 40px;
      .MuiSvgIcon-root {
        color: $LightOutline;
        height: 40px;
        width: 40px;
        margin-right: 16px;
      }
      p {
        @include body-large;
        span {
          font-weight: 700;
        }
      }
    }
    .upload-block {
      margin-left: 0;
      margin-right: 0;
      h4 {
        @include headline-large('Manrope');
      }
      p {
        @include body-medium;
        margin: 0;
      }
    }
  }
}
