@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.RefreshCompsModal {
  .MuiPaper-root {
    overflow-y: auto;
    min-width: 1032px;
    border-radius: 24px;
    padding: 3rem 3rem 2.5rem 3rem;
    margin-bottom: 250px;
  }
  .update-icon {
    width: 6rem;
    height: 6rem;
  }
  .copy-header-wrapper {
    @include flexbox(flex-start, flex-start, row);
    gap: 1.5rem;

    .header-copy {
      @include flexbox(flex-start, flex-start, column);

      h2 {
        @include headline-large('Manrope');
        margin-bottom: 0.25rem;
      }

      p {
        @include body-large;
      }
    }
  }
  .btn-wrapper {
    margin-left: auto;
    margin-top: 1.88rem;

    .btn {
      &.close-btn {
        @include outlined-button;
        margin-right: 1rem;
      }

      &.refresh-btn {
        @include blue-contained-button;
      }
    }
  }
}
