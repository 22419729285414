@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.hover-inputs {
  @include flexbox(flex-start, center);
  &.flex-end {
    @include flexbox(flex-end, center);
  }
  gap: 0.5rem;
  width: 100%;
  height: 1.375rem;
  padding: 0rem 0rem 1rem 0rem;

  .total-companies {
    @include flexbox(flex-end, center);
    @include body-medium;
    width: 100%;
    gap: 3rem;
    margin-right: 1.63rem;
    color: $Primary40;
  }

  .hover-item {
    @include flexbox(flex-start, center);
    @include body-small();
    min-height: 1.375rem;
    color: $Primary40;

    .hover-label {
      @include body-medium();
      color: $Primary40;
      margin: 0rem;
      width: auto;

      &:hover {
        color: $LightOnSurfaceVariant;
      }

      .MuiButtonBase-root {
        font-size: 1.5rem;
        padding: 0.25rem;
        margin-left: 0.125rem;
        border: 0.125rem solid transparent;

        &:focus-visible {
          outline: none;
          border: 0.125rem solid $InitioBlue;
        }

        &:active {
          outline: none;
          border: 0.125rem solid $InitioBlue;
          padding: 0.25rem;
        }
      }

      span {
        @include flexbox(flex-start, center);
        cursor: default;

        .label-text {
          white-space: nowrap;
        }

        .input-value {
          margin-left: 0.5rem;
          min-width: 44px;
        }

        .hover-input {
          height: 2rem;

          .MuiInputBase-root {
            @include body-medium();
            background-color: $White0;
            height: 2rem;
            width: 4.2rem !important;
            margin-left: 0.25rem;

            .MuiInputBase-input {
              height: 2rem;
              padding: 0.25rem;
            }
          }
        }

      }

      .edit-btn-wrapper {
        @include flexbox(center, center);
        height: 2.5rem;
        width: 2.5rem;

        .MuiButtonBase-root {
          font-size: 1.5rem;
          padding: 0.25rem;
          border: 0.125rem solid transparent;

          &:focus-visible {
            outline: none;
            border: 0.125rem solid $InitioBlue;
          }

          &:active {
            outline: none;
            border: 0.125rem solid $InitioBlue;
            padding: 0.25rem;
          }
        }
      }
    }

    &:first-child {
      min-width: 12rem;
    }
  }
}
