@import "../../../styles/mixins.scss";
@import "../../../styles/palette.scss";
@import "../../../styles/typography.scss";

.BreakpointsContainer {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 16px;
    height: 1rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #49454f;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0%);
  }
  .TableContainer {
    max-height: 21rem;
  }

  .MuiTableHead-root {
    .table-title-cell {
      box-shadow: inset 0px -1px 0px 0px #CCC, -1rem 1rem 0em #32374B;
      margin: 0rem 1rem;
      border: none;
      text-align: left;

      &:not(:first-child),
      &.breakpoint {
        margin-left: 0.5rem;
        padding-left: 0.5rem !important;
        text-align: left !important;
      }
    } 

    .MuiTableRow-root {
      .MuiTableCell-root {
        @include label-medium;
        content: '';
        color: #E1E2EC;
        background-color: #32374B;
        white-space: nowrap;
        text-align: center;
        width: 2rem;
        height: 2.5rem;
        &:nth-child(2),
        &:nth-child(3) {
          white-space: wrap;
          width: 6rem !important;
          padding: 0rem;
        }
        &:first-child {
          text-align: left;
          vertical-align: bottom;
        }
      }
      .warn-cell {
        background-color: #f1efff;
        height: 2.5rem;
        padding: 1rem 1.5rem;
        p {
          .MuiSvgIcon-root {
            margin-right: 0.5rem;
          }
          @include body-medium;
          margin: 0rem;
          color: $Tertiary30;
        }
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        @include body-small;
        text-align: right;
        white-space: nowrap;
        margin: 0rem;
        &:first-child {
          text-align: left;
          vertical-align: bottom;
        }
      }
      &.even {
        background-color: #FAFAFD;
      }
      &.odd {
        background-color: #F0F0F3;
      }
      &:nth-last-child(-n+2) {
        background-color: #9ECEBB;
        .MuiTableCell-root {
          font-weight: 600;
        }
      }
    }
  }
}
