@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.BasicInfo {
  .info-dropdown {
    margin: 28px auto;
    .header-row {
      @include flexbox(flex-start, center);
      gap: 10px;
      height: 64px;
      padding: 16px 36px;
      margin: 8px -48px 12px -48px;
      &:hover {
        background: rgba(0, 97, 164, 0.1);
      }
      &:active {
        background: rgba(0, 97, 164, 0.2);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      h5 {
        @include headline-medium;
        margin: 0 !important;
        color: $NeutralVariant30;
      }
      .edit-in-wizard-btn,
      .chevron-btn {
        .MuiSvgIcon-root {
          color: #49454f;
        }
        @include flexbox(center, center);
        border: none;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background: none;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1) !important;
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4) !important;
        }
      }
      .edit-btn {
        margin-left: auto;
        margin-right: 6px;
        .MuiSvgIcon-root {
          color: #79747e;
        }
      }
      .btns-container {
        margin-left: auto;
        margin-right: 6px;
        .save-btn {
          @include blue-contained-button;
          height: 32px;
          .loading-spinner {
            filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%)
              hue-rotate(207deg) brightness(108%) contrast(110%);
            margin-right: 8px;
            height: 16px;
            width: 16px;
            @include spin-infinitely();
          }
        }
        .cancel-btn {
          @include outlined-button;
          height: 32px;
          margin-left: 12px;
        }
      }
    }
    .data-tiles-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 300px);
      justify-content: space-around;
      row-gap: 24px;
      margin-bottom: 20px;
      .data-tile {
        .data-name {
          @include label-medium;
          color: $NeutralVariant30;
          display: block;
          opacity: 0.8;
          margin-bottom: 4px;
        }
        .data {
          @include body-large;
          color: $NeutralVariant30;
          display: block;
          word-break: break-word;
        }
      }
    }
  }
}
