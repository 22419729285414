@import '../../styles/typography.scss';
@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';

.SubNav {
  width: 100%;
  height: 3rem;
  @include flexbox(flex-start, center);
  margin-top: 1px;
  gap: 1px;
  .MuiButtonBase-root {
    background-color: $MidnightSteel;
    border-radius: 0;
    text-transform: none;
    @include flexbox(center, center);
    gap: 10px;
    @include body-large;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    color: $DarkOnPrimaryContainer;
    &.active {
      color: $DarkOnSurfaceVariant;
    }
    &.active,
    &.no-pointer-events {
      pointer-events: none;
    }
    &:hover {
      background-color: #434e68;
    }
    &:active {
      background-color: #556685;
    }
    &:focus-visible {
      background-color: #434e68;
      outline: 1px solid $InitioBlue;
    }
  }
  .top-nav-right {
    height: 48px;
    flex-grow: 1;
    background-color: $MidnightSteel;
    @include flexbox(flex-end, center);
    gap: 1rem;
    padding-right: 16px;
    .priority-dropdown {
      @include select-dropdown;
      color: #49454f;
      height: 28px;
      background-color: $NeutralVariant95;
      border-radius: 4px;
      * {
        border: none;
      }
      border: 1px solid #c9c5ca;
      &:hover {
        * {
          border: none;
        }
      }
      .MuiSelect-select {
        @include flexbox(flex-start, center);
        @include label-medium;
      }
      .MuiSvgIcon-root {
        color: #49454f;
        font-size: 20px;
      }
      .MuiButtonBase-root {
        @include flexbox;
        gap: 20px;
        .high-icon {
          color: $WarningYellow;
        }
        .low-icon {
          color: #49454f;
        }
      }
      .MuiPaper-root {
        margin-top: 16px;
      }
    }
    .status-badge-wrapper {
      @include flexbox(flex-end, center);
      gap: 1.25rem;
      .status-badge-btn {
        @include label-medium;
        padding: 0rem;
        &:hover {
          background-color: transparent;
        }
      }
      .status-badge {
        @include label-medium;
        width: fit-content;
        padding: 0.25rem 0.5rem;
        border-radius: .25rem;
        &.blue {
          color: $Primary50;
          background: $Primary95;
          border: 0.063rem solid $Primary80;
        }
        &.black {
          color: $LightOnSurfaceVariant;
          background: $NeutralVariant95;
          border: 0.063rem solid $Neutral80;
        }
        &.green {
          color: $Success50;
          background: $BackgroundGreen;
          border: 0.063rem solid $Success80;
        }
        &.purple {
          color: $Tertiary40;
          background: $Tertiary95;
          border: 0.063rem solid $Tertiary80;
        }
        &.red {
          color: $LightError;
          background: $Error95;
          border: 0.063rem solid $Error80;
        }
        .MuiSvgIcon-root {
          width: 1rem;
          height: 1rem;
          margin-left: 0.25rem;
        }
      }
    }
    .priority-wrapper {
      @include flexbox(flex-end, center);
      gap: 1.25rem;
      .priority-button {
        @include label-medium;
        width: fit-content;
        height: 1.6rem;
        padding: 0rem 0.5rem;
        border-radius: 0.25rem;
        color: $LightOnSurfaceVariant;
        background: $NeutralVariant95;
        border: 0.063rem solid $Neutral80;
        gap: 0.25rem;
      }
      .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
        margin-left: 0.25rem;
      }
    }
  }
}
#priority-menu {
  .MuiPaper-root {
    margin-top: 0.7rem !important;
    margin-right: 1rem !important;
    border-radius: 0.75rem;
    .MuiList-root {
      .MuiButtonBase-root {
        @include body-medium;
        @include spaced-between-flexbox(flex-start);
        .high-icon {
          color: $WarningYellow;
          // margin-right: 1.125rem;
        }
        .MuiSvgIcon-root {
          margin-right: 1rem;
        }
        &.active {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}