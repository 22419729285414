@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.FirstSignIn {
  .form-container {
    @include white-container(880px);
    margin: 124px auto;
    .form-header {
      @include flexbox(flex-start);
      gap: 24px;
      h4 {
        @include headline-large('Manrope');
      }
      p {
        @include body-medium;
      }
    }
    .form-grid {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
    .bottom-btn {
      @include flexbox(flex-end);
      margin-top: 24px;
      .MuiButtonBase-root {
        @include blue-contained-button;
        .loading-spinner {
          filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%)
            hue-rotate(207deg) brightness(108%) contrast(110%);
          margin-right: 8px;
          height: 16px;
          width: 16px;
          @include spin-infinitely();
        }
      }
    }
  }
}

.success-message-container {
  @include white-container(448px);
  @include flexbox(center, center);
  flex-direction: column;
  gap: 12px;
  margin: 64px auto;
  .success-message {
    margin: 24px auto 0;
    background-color: #ecf9f6;
    border-radius: 4px;
    width: 352px;
    height: 72px;
    @include flexbox(center, center);
    gap: 11px;
    .check-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      @include flexbox(center, center);
      background-color: $Success60;
      .MuiSvgIcon-root {
        fill: #fff;
        padding: 6px;
      }
    }
    span {
      @include body-medium;
      color: $Success50;
    }
  }
  .return-to-portal {
    @include text-only-button(underline);
  }
}
