// Google fonts imports
@import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap';

html {
  // Global scrollbar styling
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #49454f;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0%);
  }
}

body {
  // Font smoothing
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Background
  background: linear-gradient(161.09deg, #c5d3e8 8.61%, #e5ebf7 35.51%, #acbdda 106.03%);
  background-attachment: fixed;
  margin: 0;
  padding-right: 0rem !important;

  // Cursor and margin reset for text
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    cursor: default;
    margin: 0;
  }
}
