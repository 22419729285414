@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.edit-btn {
  margin-left: auto;
  margin-right: 6px;
}

.btns-container {
  margin-left: auto;
  margin-right: 6px;
  .save-btn {
    @include blue-contained-button;
    height: 32px;
    .loading-spinner {
      filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
      margin-right: 8px;
      height: 16px;
      width: 16px;
      @include spin-infinitely();
    }
  }
  .cancel-btn {
    @include outlined-button;
    height: 32px;
    margin-left: 12px !important;
  }
}