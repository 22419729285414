@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';

.TableElement {
  width: 1648px;
  &.third-width {
    width: 640px;
  }
  &.two-third-width {
    width: 984px;
  }
  .MuiPaper-root {
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    border: 1px solid $Outline;
    margin-top: 32px;
    box-shadow: none;
    .MuiTable-root {
      // Table Head
      .MuiTableHead-root {
        .MuiTableRow-root {
          @include flexbox(flex-start, center);
          border: none;
          box-shadow: inset 0px -1px 0px $Outline;
          background-color: $MidnightSteel;
          .MuiTableCell-root {
            color: $DarkOnSecondaryContainer;
          }
        }
      }
      
      // Table Body
      .MuiTableBody-root {
        .column-labels {
          border: none;
          box-shadow: inset 0px -1px 0px $Outline;
          background-color: $MidnightSteel !important;
          height: 48px;
          color: $DarkOnSecondaryContainer;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          font-family: 'Roboto';
          .MuiTableCell-root {
            border: none;
            @include flexbox(flex-start, center);
            text-align: right;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            height: 54px;
            color: $DarkOnSecondaryContainer;
            &:first-child {
              @include flexbox(flex-start, center);
            }
          }
        }
        .MuiTableRow-root {
          @include flexbox(flex-start, center);
          &:nth-child(odd) {
            background: $Background1;
          }
          &:last-child {
            background: none;
          }
          &.delete-background {
            background: rgba(0, 97, 164, 0.1) !important;
          }
          &.duplicate-error-row {
            padding: 16px 28px;
            border: 1px solid #b3261e;
            background-color: $Error95 !important;
            height: 48px;
            span {
              @include body-small;
              color: #b3261e;
              margin-left: 10px;
            }
          }
          .MuiTableCell-root {
            @include flexbox(center, center);
            height: 48px;
            padding: 8px 16px;
            text-align: center;
            font-size: 12px;
            border: none;
            &.first-cell-edit {
              .MuiInputBase-input {
                text-align: left !important;
              }
              @include flexbox(space-between, center);
              &:hover {
                background: rgba(0, 97, 164, 0.1);
              }
              .edit-icon-btn {
                margin-left: auto;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                padding: 7px;
                &:hover {
                  cursor: pointer;
                  background: rgba(0, 97, 164, 0.1);
                }
                &:active {
                  background: rgba(0, 97, 164, 0.4);
                }
                &:focus-visible {
                  outline: 2px solid $InitioBlue;
                }
                &.hidden {
                  opacity: 0;
                }
                &.visible,
                &:focus-visible {
                  opacity: 1;
                }
              }
            }
            &.first-cell-dropdown {
              .non-editable {
                &:hover {
                  background: none !important;
                }
              }
              .has-error {
                color: #b3261e;
              }
              padding: 0;
              gap: 0 !important;
              * {
                border-radius: 0;
              }
              * {
                box-shadow: none;
              }
              .MuiInputBase-root {
                max-height: 48px;
                &:nth-child(1) {
                  min-width: 180px;
                }
                &:nth-child(2) {
                  max-width: 550px;
                  width: 350px;
                }
                fieldset {
                  border: none;
                }
                text-align: left;
                font-size: 12px;
                border: none;
                &:hover {
                  background: rgba(0, 97, 164, 0.1);
                }
                .MuiSelect-select {
                  &:focus-visible {
                    box-shadow: inset 0px 0px 0px 1px $InitioBlue;
                  }
                }
              }
              @include flexbox(flex-start, center);
              gap: 4px;
            }
            &.delete-cell {
              margin-left: auto;
              @include flexbox(center, center);
              cursor: pointer;
              &.disabled {
                pointer-events: none;
                opacity: 0.4;
              }
            }
            &.non-editable {
              pointer-events: none;
            }
            &.has-error {
              color: #b3261e;
              background-color: $Error95 !important;
              &:hover {
                background-color: $Error90 !important;
                * {
                  border: none !important;
                }
                .MuiInputBase-root {
                  border: 1px solid #b3261e !important;
                }
              }

              .MuiFormControl-root {
                * {
                  border: none;
                }
                .MuiInputBase-root {
                  border: 1px solid #b3261e;
                }
              }
            }
            // TODO Refactor CSS for forms
            .MuiFormControl-root {
              min-width: 100%;
              background: #ffffff;
              border-radius: 5px;
              * {
                border-radius: 5px;
              }
              * {
                box-shadow: none;
              }
              .MuiInputBase-input {
                text-align: center;
                font-size: 12px;
                padding: 7px 6px;
                &::placeholder {
                  opacity: 1;
                }
              }
              .MuiSelect-select {
                padding: 4px 5px;
                padding-right: 25px;
              }
              .MuiSvgIcon-root {
                position: absolute;
              }
              &.date-picker {
                .MuiInputBase-root {
                  padding-right: 0px;
                  .MuiInputBase-input {
                    text-align: left;
                  }
                  .MuiButtonBase-root {
                    &:hover {
                      background: none;
                    }
                  }
                  .MuiSvgIcon-root {
                    right: 16px;
                  }
                  .has-error {
                    color: #b3261e;
                  }
                }
              }
            }
            .MuiFormControl-root {
              &.date-picker {
                margin-left: 5px;
                &.has-error {
                  color: #b3261e;
                  background-color: $Error95 !important;
                  &:hover {
                    background-color: $Error90 !important;
                    * {
                      border: none !important;
                    }
                    .MuiInputBase-root {
                      border: 1px solid #b3261e !important;
                    }
                  }
                  .MuiFormControl-root {
                    * {
                      border: none;
                    }
                    .MuiInputBase-root {
                      border: 1px solid #b3261e;
                    }
                  }
                }
              }
            }
            .date-clear-icon {
              color: #49454f;
              border-radius: 50%;
              height: 22px;
              width: 22px;
              padding: 2px;
              margin-right: 5px;
              margin-left: 2px;
              &.active {
                &:hover {
                  background-color: rgba(0, 97, 164, 0.1) !important;
                }
                &:focus-visible {
                  outline: 2px solid $InitioBlue;
                }
                &:active {
                  background-color: rgba(0, 97, 164, 0.4) !important;
                }
              }
              &.inactive {
                opacity: 0.5;
              }
            }
            &.select-field {
              .MuiSvgIcon-root {
                right: 2px;
              }
            }
            &.add-new-field-cell {
              @include flexbox(flex-start, center);
              gap: 4px;
              background: none;
              border: none;
              color: $Primary40;
              &:hover {
                cursor: pointer;
                background: rgba(0, 97, 164, 0.1);
              }
              .MuiSvgIcon-root {
                margin-left: 0;
              }
            }
            &:not(.non-editable):not(.delete-cell):not(.first-cell-dropdown) {
              cursor: pointer;
              * {
                cursor: pointer;
              }
              &:hover {
                background: rgba(0, 97, 164, 0.1);
              }
            }
            .set-equity-val-btn {
              @include outlined-button;
              padding: 2px 12px;
              height: 24px;
            }
          }
          &.total-row {
            .MuiTableCell-root {
              font-weight: 600;
              &.total-first-cell {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }
  .text-field-select,
  .first-cell-dropdown {
    .MuiPaper-root {
      border: none;
      background-color: $Container2;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
      @include select-dropdown;
      .MuiButtonBase-root {
        @include body-small;
        border-radius: 0px !important;
        &[aria-selected='true'] {
          background-color: rgba(66, 149, 243, 0.08) !important;
          box-shadow: 4px 0 0 $InitioBlue inset !important;
        }
        &:hover,
        &:focus-visible {
          background-color: rgba(66, 149, 243, 0.08) !important;
        }
        &:focus-visible {
          box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset,
            0 -2px 0 $InitioBlue inset, -2px 0 0 $InitioBlue inset !important;
        }
      }
    }
  }
}

.option-warrant-dropdown {
  border: none;
  background-color: $Container2;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
  @include select-dropdown;
  .MuiButtonBase-root {
    @include body-small;
    border-radius: 0px !important;
    &[aria-selected='true'] {
      background-color: rgba(66, 149, 243, 0.08) !important;
      box-shadow: 4px 0 0 $InitioBlue inset !important;
    }
    &:hover,
    &:focus-visible {
      background-color: rgba(66, 149, 243, 0.08) !important;
    }
    &:focus-visible {
      box-shadow: 4px 0 0 $InitioBlue inset, 0 2px 0 $InitioBlue inset, 0 -2px 0 $InitioBlue inset,
        -2px 0 0 $InitioBlue inset !important;
    }
  }
}
