@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

@mixin table-scrollbar() {
  &::-webkit-scrollbar {
    width: 18px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: $DarkOutline;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0%);
  }
}

.Calculations {
  width: 1344px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70px;
  .generate-report-button {
    @include flexbox(flex-end);
    margin-bottom: 24px;
    .MuiButtonBase-root {
      @include blue-contained-button;
      height: 40px;
    }
  }
  .header-info-block {
    border-radius: 8px;
    background-color: $LightSurfaceVariant;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
    padding: 32px 20px;
    margin-bottom: 24px;
    h4 {
      .MuiSvgIcon-root {
        font-size: 48px;
        margin-right: 8px;
      }
      @include display-small('Manrope');
    }
    p,
    ul {
      @include body-small;
      color: $LightOutline;
      margin: 0;
    }
    .header-content {
      @include flexbox();
      gap: 24px;
      .info-block {
        background-color: $BlueSteel;
        padding: 12px;
        padding-right: 64px;
        border-radius: 12px;
        color: $CoolSteel;
        .MuiSvgIcon-root {
          margin-top: 6px;
          margin-bottom: 6px;
        }
        h5 {
          @include label-large;
          color: $CoolSteel;
          margin: 0;
        }
        span {
          font-style: italic;
          font-size: 24px;
        }
        .user-data {
          @include label-large;
          color: #fdfeff;
          font-style: normal;
        }
      }
    }
  }

  .sensitivity-analysis-wrapper {
    padding: 12px 12px;
    background-color: $NeutralVariant95;
    border-radius: 8px;
    margin-top: 24px;
    .sensitivity-analysis {
      padding: 1.5rem 0px;
      margin: 8px;
      border-radius: 12px;
      border: 1px solid $Outline;
      background-color: $Container2;
      .range-inputs-header {
        @include label-large;
        margin-left: 24px;
        margin-bottom: 24px;
      }
      .range-inputs {
        margin: 8px 0px 0px 16px;
        @include flexbox;
        gap: 4px;
        .term-val,
        .vol-val,
        .term-input,
        .volatility-input {
          h6 {
            margin-left: 8px;
            margin-bottom: 12px;
          }
          .round-icon-wrapper {
            @include flexbox(center, center);
            border-radius: 50%;
            height: 1rem;
            width: 1rem;
            .MuiSvgIcon-root {
              color: $Primary40;
              height: 1rem;
              width: 1rem;
              margin: 0;
              padding: 0;
            }
            &:hover {
              border: 2px solid #dde7ed;
              outline: 2px solid #dde7ed;
            }
          }
          .MuiFormControl-root {
            width: 115px;
            margin: 0 8px;
            .MuiFormLabel-root {
              @include flexbox(flex-start, center);
              gap: 2px;
              margin-right: 1px;
              top: 1px;
              .round-icon-wrapper {
                margin-left: -16px;
                margin-bottom: 2px;
                height: 18px;
                width: 18px;
                &.disabled {
                  pointer-events: none;
                  .MuiSvgIcon-root {
                    color: $LightOnSurfaceVariant;
                    opacity: 0.8;
                  }
                }
                .MuiSvgIcon-root {
                  color: $Primary40;
                  height: 18px;
                  width: 18px;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
          .input-title-and-icon {
            @include flexbox(flex-start, center);
            gap: 4px;
            h6 {
              margin-bottom: 0;
            }
            margin-bottom: 12px;
          }
        }
        .term-val,
        .vol-val {
          .MuiFormControl-root {
            width: 148px;
            margin: 0 16px 0 8px;
            .MuiInputBase-root {
              border-radius: 12px;
              .MuiOutlinedInput-notchedOutline {
                border-color: $Primary30;
              }
            }
          }
        }
        .vl {
          height: 100px;
          margin: 0 8px;
          border-right: 1px solid $Success50;
        }
      }
      .sensitivity-analysis-header {
        @include label-large;
        margin: 32px 0 16px 24px;
        span {
          @include body-medium;
          color: $LightOutline;
          margin-left: 16px;
        }
      }
      .run-analysis-btn {
        @include flexbox(flex-end);
        margin: 24px;
        margin-top: 12px;
        .MuiButtonBase-root {
          height: 40px;
          @include outlined-button;
        }
      }
      .vol-table-header {
        @include flexbox(flex-start, center);
        background-color: $MidnightSteel;
        @include label-medium;
        color: $DarkOnSecondaryContainer;
        height: 47px;
        padding-left: 16px;
        width: auto;
      }
      .volatility-table {
        border: none;
        border-radius: 0;
        .fixed-left {
          border-top-left-radius: 0;
          .table-header {
            overflow: visible;
          }
        }
        .table-sub-headers {
          height: 56px;
        }
        .table-header-top-titles {
          @include flexbox(flex-start, center);
          div {
            height: 3rem;
            border-bottom: 1px solid $Outline;
            margin: 0 8px;
            h6 {
              font-size: 12px;
              margin: 0;
              text-align: center;
            }
            &.volatility {
              min-width: 12.47rem;
              margin: 0rem;
            }
          }
        }
      }
    }
  }

  .no-calc-text {
    @include flexbox(center, center);
    width: 1334px;
    height: 104px;
    border: 1px solid $LightOutline;
    border-radius: 12px;
    @include label-medium;
    background-color: #cfdcec;
  }

  .calc-wrapper {
    .calc-header {
      background-color: $LightSurfaceVariant;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 16px 32px;
      @include flexbox(flex-start, center);
      gap: 8px;
      h4 {
        @include flexbox(flex-start, center);
        gap: 16px;
        @include title-large('Manrope');
        color: $LightOnSurfaceVariant;
        margin: 0;
        margin-right: 16px;
      }
      p {
        @include body-small;
        color: $LightOutline;
      }
      .run-calc-btn {
        @include blue-contained-button;
        height: 40px;
        margin-left: auto;
        .MuiSvgIcon-root {
          color: inherit;
        }
      }
    }
    .calc-in-progress,
    .calc-complete {
      padding: 16px 24px;
      @include flexbox(flex-start, center);
      gap: 16px;
      background-color: $BackgroundGreen;
      @include body-medium;
      color: $Success50;
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      .dots-circle-spinner {
        font-size: 22px;
      }
      .check-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        @include flexbox(center, center);
        background-color: $Success60;
        .MuiSvgIcon-root {
          fill: #fff;
          padding: 6px;
        }
      }
      .close-icon {
        margin-left: auto;
        color: #999ba5;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 2px;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1);
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4);
        }
      }
    }
    .weight-notification {
      padding: 16px 24px;
      @include flexbox(flex-start, center);
      gap: 16px;
      background-color: $Tertiary95;
      @include body-medium;
      color: $Tertiary30;
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      .MuiSvgIcon-root {
        color: $LightTertiary;
      }
    }

    .calc-content {
      padding: 24px 40px;
      background-color: $Container2;
      .inputs-wrapper {
        @include flexbox;
      }
      h6 {
        @include label-large;
        margin-bottom: 24px;
        .MuiSvgIcon-root {
          margin-right: 8px;
        }
      }
      .vl {
        height: 140px;
        border-right: 1px solid $Outline;
        margin-left: 24px;
        margin-right: 24px;
      }
      .market-adjust {
        @include flexbox;
        gap: 24px;
        h6 {
          @include label-large;
          margin-bottom: 16px;
        }
        .MuiFormControl-root {
          width: 283px;
        }
        .radio-input-block {
          width: 284px;
          background-color: $NeutralVariant95;
          padding: 12px;
          border-radius: 8px;
          margin-right: 24px;
          span {
            @include body-small;
          }
          .Mui-checked {
            .MuiSvgIcon-root {
              color: $Primary40;
            }
            .MuiTypography-root {
              @include body-medium;
              font-size: 14px;
            }
          }
        }
        .suggested-market-adjustment {
          @include flexbox;
          gap: 16px;
          .MuiInputBase-root {
            @include title-medium('Manrope');
            width: 283px;
            border-radius: 8px;
            background-color: $Tertiary95;
            .MuiOutlinedInput-notchedOutline {
              border-color: $InitioPurple;
            }
            .MuiOutlinedInput-input {
              &.Mui-disabled {
                color: $LightOnSurfaceVariant !important;
                -webkit-text-fill-color: $LightOnSurfaceVariant !important;
              }
            }
            .MuiSvgIcon-root {
              color: $InitioPurple;
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.25rem;
              margin-left: 4px;
            }
          }
        }
      }
      .weights-inputs {
        @include flexbox;
        .vl {
          height: 100px;
          border-right: 1px solid $Outline;
          margin-left: 24px;
          margin-right: 24px;
        }
        .weight-input-block {
          h6 {
            color: $Success50;
            margin-bottom: 16px;
          }
          .MuiFormControl-root {
            width: 286px;
            .MuiInputBase-root {
              @include body-large;
              color: $Success50;
              border-color: $Primary30;
            }
          }
        }
      }

      .footnote-btn {
        @include text-only-button;
        margin: 16px;
        margin-bottom: 0;
      }

      .footnotes-area {
        margin-top: 16px;
        background-color: $NeutralVariant95;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 16px;
        column-gap: 1.5rem;

        .MuiInputBase-root {
          background-color: $Container2;
          width: 38rem;
          min-height: 5.5rem;
          font-size: 14px;
          min-height: 84px;
          background-color: white;
          .MuiInputBase-input {
            margin-bottom: auto;
          }
          textarea::-webkit-scrollbar {
            display: none;
          }
          .MuiInputAdornment-root {
            margin-top: auto;
            margin-bottom: 10px;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }

        .fn-label {
          grid-column: span 2;
        }
        .sub-header {
          @include label-large;
          color: #79747e;
          grid-column: span 2;
          margin-bottom: 0.5rem !important;
          &.fairValuePerShare {
            margin: 1rem 0rem;
          }
        }
        .fn-label{ 
          grid-column: span 2;
          @include label-large;
          margin-bottom: 0rem !important;
        }

        .revert-btn {
          @include text-only-button;
          color: $Primary30;
          display: flex;
          margin-left: auto;
          padding-right: 0.75rem;
          font-weight: 400;
          grid-column: span 2;
          .MuiSvgIcon-root {
            font-size: 1.25rem;
            margin-left: 0.5rem;
            font-weight: 600;
            transform: scaleX(-1);
          }
        }

        .footnote-characters-left {
          @include body-small;
          text-align: right;
          grid-column: span 2;
          padding-right: 0.35rem;
        }

        .weighting-blocks {
          display: grid;
          grid-column: span 2;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 1.5rem;
          .block-header {
            @include label-large;
            display: grid;
            grid-column: 1 / 3;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1.5rem;
            .MuiInputBase-root {
              width: 38rem;
              margin-bottom: 1rem;
            }
            .MuiSvgIcon-root {
              font-size: 1.5rem;
              margin-right: 0.75rem;
            }
          }
          .weight-block {
            @include flexbox(flex-start, flex-start, row);
            .fn-input {
              @include flexbox(flex-start, flex-start, column);
              p {
                @include label-large;
                color: $LightOutline;
                margin-bottom: 0.5rem;
              }
              .MuiInputBase-root {
                width: 30rem;
                margin-bottom: 0.25rem;
              }
            }
            .weight-container {
              @include flexbox(center, center, column);
              margin-left: auto;
              p {
                @include label-large;
                margin-bottom: 0.5rem;
              }
              div {
                @include flexbox(center, center, column);
                height: 2.5rem;
                width: 5.5rem;
                border-radius: 0.5rem;
                border: 2px solid #bdc2ff;
                background: #f1efff;
                span {
                  @include label-large;
                  color: $Tertiary40;
                }
                h6 {
                  @include label-medium;
                  font-size: 0.5625rem;
                  color: $Tertiary40;
                  margin-bottom: 0rem;
                }
              }
            }
            .footnote-characters-left {
              grid-column: span 2;
              width: 30rem;
            }
          }
        }
        .footnotes {
          margin-top: 16px;
          background-color: $NeutralVariant95;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 1.5rem ;
          p {
            @include label-large;
            grid-column: span 2;
            margin-bottom: 0rem !important;
            .MuiSvgIcon-root {
              font-size: 1.5rem;
              margin-right: 0.75rem;
            }
          }
          &.span-two {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;
            padding: 0rem;
            p {
              @include label-large;
              grid-column: span 2;
              margin-bottom: 0rem !important;
              .MuiSvgIcon-root {
                font-size: 1.5rem;
                margin-right: 0.75rem;
              }
            }
            grid-column: span 2;
          }
        }
      }
      .methodology-summary {
        display: grid;
        grid-column: span 2;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        h6 {
          @include label-large;
          grid-column: span 2;
          margin-bottom: 0rem !important;
          .MuiSvgIcon-root {
            font-size: 1.5rem;
            margin-right: 0.75rem;
          }
        }
        .gpc-criteria {
          display: grid;
          grid-column: span 2;
          column-gap: 1.5rem;
          div {
            p {
              @include label-large;
              color: $LightOutline;
              margin-bottom: 0.5rem;
            }
            .footnote-characters-left {
              margin-right: 1.1rem;
              margin-top: 0.25rem;
            }
            &:nth-child(1) {
              grid-column: 1 / 2;
              .revert-btn {
                margin-top: 0.56rem;
              }
            }
            &:nth-child(2) {
              grid-column: 2 / 2;
              .revert-btn {
                margin-top: 0.56rem;
              }
            }
          }
        }
      }
    }
    .calc-footer {
      border-top: 2px solid $Outline;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: $LightSurfaceVariant;
      height: 32px;
    }
  }

  .calc-engine-outputs {
    background-color: #eaebf0;
    border-radius: 8px;
    padding-top: 32px;
    .calc-engine-outputs-header {
      @include flexbox(flex-start, center);
      margin: 0px 32px 16px;
      p {
        @include body-small;
      }
      h6 {
        margin-right: 16px;
        margin-bottom: 0;
      }
      .MuiButtonBase-root {
        @include outlined-button;
        height: 40px;
        margin-left: auto;
      }
    }
    .no-outputs-to-show {
      @include flexbox(center, center);
      @include label-medium;
      background-color: #e4e5ec;
      color: $LightOutline;
      text-align: center;
      border-radius: 12px;
      height: 104px;
      .dots-circle-spinner {
        color: $Primary40;
        font-size: 23px;
      }
    }
    .outputs-values-row {
      @include flexbox(flex-start, center);
      gap: 24px;
      padding: 16px;
      .value-block {
        height: 88px;
        width: 218px;
        margin: 0 2px;
        @include flexbox(center, center, column);
        border-radius: 8px;
        background-color: #42a179;
        &.large {
          width: 291px;
        }
        span {
          text-align: center;
          @include body-small;
          font-weight: 600;
          color: $Background1;
          &:first-child {
            @include label-large;
            font-weight: 900;
            color: #fff;
            font-size: 16px;
          }
        }
      }
      .vl {
        height: 88px;
        margin: 0;
      }
      p {
        background-color: $White30;
        padding: 8px 12px;
        border-radius: 8px;
        margin: 0;
        @include label-medium;
        color: $LightOutline;
      }
    }
  }

  .volatility-table,
  .backsolve-table,
  .pubCo-table,
  .mAndA-table,
  .concluded-table {
    border: 1px solid $Outline;
    border-radius: 8px;
    overflow: hidden;
    @include flexbox();
    .fixed-left {
      border-top-left-radius: 8px;
      position: absolute;
      box-shadow: 0px 1px 8px 1px rgba(0, 49, 93, 0.2);
      clip-path: inset(0px -8px 0px -0px);
      .table-header {
        width: 72px;
      }
      .table-rows {
        overflow: hidden;
      }
    }
    .right-scrollable {
      margin-left: 72px;
      width: 1151px;
    }
    .table-header {
      background-color: $MidnightSteel;
      overflow-x: hidden;
      .table-header-top-titles {
        @include flexbox(flex-start, center);
        div {
          height: 56px;
          border-bottom: 1px solid $Outline;
          h6 {
            @include label-large;
            color: $DarkOnSecondaryContainer;
            margin-top: 30px;
            margin-bottom: 2px;
            margin-left: 16px;
          }
          p {
            @include label-large;
            color: $Secondary70;
            font-style: normal;
          }
          &.term {
            min-width: 5rem;
            margin: 0rem;
          }
          &.volatility {
            min-width: 184px;
            height: 3rem;
            @include flexbox(center, flex-end);
            padding: 0.5rem;
            h6 {
              text-align: center;
              margin-top: 1rem;
            }
            &.center {
              background-color: rgba(255, 255, 255, 0.08);
            }
          }
          &.concluded {
            min-width: 1237px;
            &.method-title {
              height: 3.125rem;
              margin: 1rem 1rem 0rem 0rem;
              padding-left: 1rem;
              h6 {
                margin: 0rem;
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            min-width: 470px;
          }
        }
      }
      .table-sub-headers {
        @include flexbox(flex-start, center);
        height: 32px;
        .sub-header {
          @include flexbox(center, center, column);
          gap: 6px;
          h6,
          span {
            @include label-medium;
            text-align: center;
          }
          h6 {
            margin: 0;
            color: $DarkOnSecondaryContainer;
          }
          span {
            color: $Secondary70;
          }
        }
        .sub-header-group {
          @include flexbox;
          h6 {
            margin-left: 12px;
          }
          &.term {
            @include flexbox(center, center);
            height: 56px;
            border-right: 1px solid #ccc;
            .sub-header {
              min-width: 72px;
              h6 {
                margin-left: 0;
              }
            }
          }
          &.volatility {
            .sub-header {
              min-width: 192px;
              @include flexbox(flex-start, flex-end);
              border-right: 2px solid $Secondary70;
              height: 56px;
              padding-bottom: 2px;
              span {
                text-align: center;
                width: 64px;
                &:first-child {
                  width: 128px;
                }
                color: $DarkOnSecondaryContainer;
              }
              &.center {
                background-color: rgba(255, 255, 255, 0.08);
              }
            }
          }
          &.concluded {
            .sub-header {
              &:nth-child(1) {
                h6 {
                  margin-right: auto;
                }
                min-width: 200px;
              }
              &:nth-child(n + 2) {
                min-width: 120px;
                h6 {
                  margin-left: 0;
                }
              }
              &:nth-child(7) {
                min-width: 160px;
                h6 {
                  margin-left: 0;
                }
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            min-width: 470px;
            .sub-header {
              &:nth-child(1) {
                align-items: flex-start;
                min-width: 200px;
              }
              &:nth-child(2) {
                min-width: 104px;
              }
              &:nth-child(3) {
                min-width: 160px;
              }
            }
          }
        }
      }
    }
    .warning-message {
      position: relative;
      background-color: $Tertiary95;
      margin-left: -4.5rem;
      height: 3.5rem;
      z-index: 10000000;
      padding: 1rem 1.5rem;
      p {
        @include body-medium;
        color: $Tertiary30;
        .MuiSvgIcon-root {
          margin-right: 0.5rem;
          width: 1.5rem;
          height: 1.5rem;
          color: $Tertiary50;
        }
      }
    }
    .table-rows {
      overflow: auto;
      @include table-scrollbar();
      .table-row-data {
        @include flexbox(flex-start, center);
        &:nth-child(odd) {
          .table-cell {
            background-color: $Background1;
            &.center {
              background-color: #eff0fb;
            }
          }
        }
        &:nth-child(even) {
          .table-cell {
            background-color: $Container2;
            &.center {
              background-color: #e0e2ef;
            }
          }
        }
        .row-data-group {
          @include flexbox;
          .table-cell {
            height: 32px;
            @include body-small;
            @include flexbox(center, center);
            &:last-child {
              flex-grow: 1;
            }
          }
          &.term {
            .table-cell {
              &:nth-child(1) {
                min-width: 72px;
              }
            }
          }
          &.volatility {
            width: 1271px;
            .table-cell {
              min-width: 200px;
              &.filler-cell {
                min-width: auto;
              }
              span {
                text-align: center;
                width: 66px;
                &:first-child {
                  width: 132px;
                }
              }
              border-right: 1px solid $Container2;
              &.can-hover {
                &:hover {
                  background: rgba(0, 97, 164, 0.1);
                }
                &:focus-visible {
                  outline: none;
                  box-shadow: inset 0px 0px 0px 1px $InitioBlue;
                }
                &.selected {
                  box-shadow: inset 0px 0px 0px 1px $InitioBlue;
                  font-weight: 600;
                  span {
                    @include flexbox(center, center);
                    height: 32px;
                    &:first-child {
                      border-right: 1px solid $InitioBlue;
                    }
                    &:last-child {
                      border-left: 1px solid $InitioBlue;
                    }
                  }
                }
              }
              &.no-data {
                pointer-events: none;
              }
              .MuiSvgIcon-root {
                margin-left: 8px;
                color: $InitioBlue;
                font-size: 16px;
                visibility: hidden;
              }
              &.selected {
                .MuiSvgIcon-root {
                  visibility: visible;
                }
              }
            }
          }
          &.backsolve,
          &.pubCo,
          &.mAndA {
            .table-cell {
              &:nth-child(1) {
                justify-content: flex-start;
                padding-left: 12px;
                min-width: 200px;
              }
              &:nth-child(2) {
                min-width: 110px;
              }
              &:nth-child(3) {
                min-width: 160px;
              }
            }
          }
          &.concluded {
            width: 1237px;
            .table-cell {
              &:nth-child(1) {
                justify-content: flex-start;
                padding-left: 12px;
                min-width: 200px;
              }
              &:nth-child(n + 2) {
                min-width: 120px;
              }
              &:nth-child(7) {
                min-width: 160px;
              }
            }
          }
        }
      }
    }
  }
  .backsolve-table,
  .pubCo-table,
  .mAndA-table,
  .concluded-table {
    flex-direction: column;
    .table-rows {
      overflow-x: visible;
    }
  }
  .tables-wrapper {
    background-color: $NeutralVariant95;
    padding: 12px;
  }
  .methods-table {
    @include flexbox(center);
    &.three-tables {
      @include flexbox(space-between);
    }
    overflow-x: auto;
    @include table-scrollbar();
    margin-bottom: 24px;
    padding: 24px;
    .backsolve-table,
    .pubCo-table,
    .mAndA-table {
      border-radius: 8px;
      .table-header {
        overflow: visible;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .table-rows {
        overflow-x: visible;
        overflow-y: auto;
        @include table-scrollbar();
      }
    }
    .total-row {
      @include flexbox;
      .table-cell {
        @include flexbox(center, center);
        height: 32px;
        background-color: #54aa87;
        &:nth-child(1) {
          min-width: 310px;
          border-bottom-left-radius: 8px;
        }
        &:nth-child(2) {
          text-align: center;
          min-width: 160px;
          background-color: #42a179;
          @include label-medium;
          color: #fff;
          border-bottom-right-radius: 8px;
        }
      }
    }
    .backsolve-table {
      margin-left: 24px;
    }
    .pubCo-table {
      margin: 0 24px;
    }
    .mAndA-table {
      margin-right: 24px;
    }
  }
  .concluded-table {
    .total-row {
      @include flexbox;
      width: 100%;
      background-color: #42a179;
      .table-cell {
        @include label-medium;
        @include flexbox(center, center);
        height: 32px;
        color: #fff;
        
        &:nth-child(1) {
          min-width: 200px;
          border-bottom-left-radius: 8px;
        }
        &:nth-child(2) {
          min-width: 120px;
          border-bottom-left-radius: 8px;
        }
        &:nth-child(7) {
          min-width: 160px;
          border-bottom-left-radius: 8px;
        }
        min-width: 120px;
      }
    }
    .final-row {
      @include flexbox;
      .table-cell {
        @include label-medium;
        @include flexbox(center, center);
        height: 32px;
        background-color: $Background1;
        &:nth-child(1) {
          min-width: auto;
          padding-left: 12px;
        }
        &:nth-child(2) {
          min-width: auto;
          text-align: left;
          padding-left: 12px;
        }
      }
    }
  }
}

.bottom-arrow-tooltip {
  &.centered-text {
    text-align: center;
  }
  &.longer {
    .MuiTooltip-tooltip {
      min-width: 426px;
    }
  }
}
