@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ProjectStatusCard {
  margin-left: 0.85rem;
  @include flexbox(center, center, column);

  .card-btn {
    @include spaced-between-flexbox(space-between, flex-start, column);
    min-width: 20.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background-color: $White20;
    padding: 1.5rem 1.5rem 1rem;
    box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(0, 49, 93, 0.20);
    border: 0.125rem solid transparent;

    &:focus-visible {
      outline: none;
      border: 0.125rem solid $InitioBlue;
    }

    &:hover {
      cursor: pointer;
      border-radius: 0.5rem;
      background: linear-gradient(0deg, rgba(0, 97, 164, 0.05) 0%, rgba(0, 97, 164, 0.05) 100%), #FAFAFD;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
    }
    &.can-hover {
      background: $White20;
    }

    .card-header {
      @include flexbox(flex-start, flex-start);
      min-height: 2.5rem;

      .service-name {
        @include flexbox(center, center);
        background: $Primary75;
        border-radius: 0.75rem;
        padding: 0.5rem 0.75rem;
        width: 4rem;
        height: 2.5rem;
        color: $White30;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.006rem;
        cursor: default;
      }

      .card-header-info {
        @include flexbox(flex-start, flex-start, column);
        @include body-medium;
        padding: 0rem 0.62rem;
        white-space: nowrap;

        .fund-name {
          @include label-large;
          color: var(--m-3-sys-light-secondary, #303D5C);
          max-width: 10.5rem;
        }
      }

      .priority-icon-wrapper {
        @include flexbox(flex-end, center);
        margin-left: auto;
        height: 100%;

        .priority-icon {
          color: $WarningYellow;
        }
      }
    }

    .status-badge-wrapper {
      @include flexbox(flex-end);
      margin-top: 0.52rem;
      margin-left: auto;
      cursor: default;

      .status-badge-btn {
        padding: 0rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .status-badge {
        @include label-medium;
        width: fit-content;
        padding: 0.25rem 0.5rem;
        border-radius: .25rem;
        &.blue {
          color: $Primary50;
          background: $Primary95;
          border: 0.063rem solid $Primary80;
        }
        &.black {
          color: $LightOnSurfaceVariant;
          background: $NeutralVariant95;
          border: 0.063rem solid $Neutral80;
        }
        &.green {
          color: $Success50;
          background: $BackgroundGreen;
          border: 0.063rem solid $Success80;
        }
        &.purple {
          color: $Tertiary40;
          background: $Tertiary95;
          border: 0.063rem solid $Tertiary80;
        }
        &.sub-status {
          margin-right: 0.5rem;
        }
        &.red {
          color: $LightError;
          background: $Error95;
          border: 0.063rem solid $Error80;
        }
        .MuiSvgIcon-root {
          width: 1rem;
          height: 1rem;
          margin-left: 0.25rem;
        }
      }
    }

    .card-footer-wrapper {
      @include flexbox();
      margin-bottom: 0.5rem;

      .card-footer-info {
        @include flexbox(flex-start, flex-start, column);
        @include label-small;

        .date-info-due {
          @include flexbox(center, center, row);
          margin-top: 0.7rem;
          gap: 0.62rem;
          margin-left: 0.8rem;

          &.no-assignees {
            margin-left: 0rem;
          }

          .MuiAvatarGroup-root {
            .MuiAvatar-root {
              // line height in the mixin body-small was causing the initials to be off center
              font-family: "Roboto";
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: 1.0rem;
              letter-spacing: 0.025rem;
              width: 1.75rem;
              height: 1.75rem;
              text-align: center;

              &:active,
              &:focus {
                box-shadow: 0 0 0 0.063rem $White20, 0 0 0 0.188rem #c9d5e1;
              }

              &:focus-visible {
                box-shadow: 0 0 0 0.063rem $White20, 0 0 0 0.188rem #c9d5e1, 0 0 0 0.313rem #4295F3;
              }
            }
          }
        }
      }

      .card-expand-chevron {
        @include flexbox(center, center);
        margin-left: auto;
        height: 2rem;
        width: 2rem;
        background: none;
        border: none;
        border-radius: 50%;
        color: $Primary40;
        margin-top: 0.8rem;
        cursor: pointer;

        &:hover {
          background-color: #e6f0f6;
          cursor: pointer;
        }

        &:focus-visible {
          outline: 0.125rem solid $InitioBlue;
        }

        &:active {
          background-color: #99c0db;
        }

        .MuiSvgIcon-root {
          cursor: pointer;

          path {
            cursor: pointer;
          }
        }
      }
    }

    .card-spacer {
      margin: 0rem;
    }

    .expanded-content {
      .links-wrapper {
        padding: 0.75rem 0rem 0rem;

        .assigned-to-section {
          @include flexbox(flex-start, flex-start, column);
          min-height: 15.75rem;
          background-color: rgba(0, 72, 132, 0.08);
          border-radius: 0.75rem;
          padding: 0.75rem;
          margin-bottom: 0.75rem;

          span {
            padding: 0rem;
            font-size: 0.75rem;
            font-weight: 600;
            line-height: 1.25rem;
            letter-spacing: 0.0.313rem;
            text-transform: none;
            color: $Primary40;
          }

          h6 {
            @include label-medium;
            color: #49454F;
          }

          hr {
            color: #CCCCCC;
            border-top: var(--bs-border-width) solid;
            width: 100%;
            margin-bottom: 0.5rem;
          }

          .assigned-to-title {
            min-height: 4.5rem;
          }

          .assignee-avatars {
            @include flexbox();
            margin-top: 0.25rem;
            margin-bottom: 0.5rem;
            flex-wrap: wrap;
            max-width: 15rem;
            min-width: 14.5rem;
            gap: 0% 1%;

            :nth-child(n+7) {
              margin-top: 0.5rem;
              /* Adjust the margin to control the gap */
            }

            .bottom-arrow-tooltip {
              white-space: pre-line;
            }

            .MuiAvatar-root {
              @include body-small;
              margin-right: 0.5rem;
              width: 1.75rem;
              height: 1.75rem;
              box-shadow: 0 0 0 0.063rem $White20;

              &:active,
              &:focus {
                box-shadow: 0 0 0 0.063rem $White20, 0 0 0 0.188rem #c9d5e1;
              }

              &:focus-visible {
                box-shadow: 0 0 0 0.063rem $White20, 0 0 0 0.188rem #c9d5e1, 0 0 0 0.313rem #4295F3;
              }
            }

            .working-companies {
              @include label-small;
              background-color: #313033;
              color: #EFF0F3;
              box-shadow: none;
              width: 1rem;
              height: 1rem;
              text-align: center;
              padding: 0.25rem 0.25rem 0.1rem 0.30rem;
              width: auto;
            }

            .initials {
              &.dark {
                color: #49454F;
              }

              &.light {
                color: #FFFFFF;
              }
            }
          }
        }

        .expanded-links {
          @include flexbox(flex-start, center);
          @include light-filled-button(false);
          border-radius: 0.75rem;
          width: 100%;
          height: 3rem;
          margin-bottom: 0.75rem;
          padding: 0rem 0.75rem;
          padding-left: 0.75rem;
          cursor: pointer;
          background-color: rgba(0, 72, 132, 0.08);
          &.investor-details {
            display: flex;
            justify-content: center;
            text-align: center;
            text-decoration: underline;
            white-space: nowrap;
            max-width: 17.271rem;
          }

          &.dropdown-select {
            * {
              border: none !important;
            }

            &:hover {
              * {
                border: none !important;
              }
            }

            border: none;
            font-size: 0.75rem;

            // cursor: pointer;
            .priority-label {
              @include label-medium;
              color: $Primary40;
              cursor: pointer;
              &:focus-visible {
                outline: none;
                border: 0.125rem solid $InitioBlue;
              }
            }

            svg {
              color: $Primary40;
            }

            .MuiSelect-select {
              padding: 0;
              margin: 0;
              align-items: center;
              @include spaced-between-flexbox(flex-start);
              cursor: pointer;
            }

            .MuiPaper-root {
              margin-top: 0.375rem;
              @include select-dropdown;

              .MuiButtonBase-root {
                @include body-medium;
                @include spaced-between-flexbox(flex-start);

                .high-icon {
                  color: $WarningYellow;
                  // margin-right: 1.125rem;
                }
              }
            }
          }
        }

        .due-dates {
          @include flexbox(flex-start, flex-start, column);
          background-color: rgba(0, 72, 132, 0.08);
          padding: 0.75rem;
          color: $Primary40;
          font-family: 'Roboto';
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
          /* 166.667% */
          letter-spacing: 0.03125rem;
          border-radius: 0.75rem;

          span {
            @include flexbox(flex-start, center)
          }

          .MuiSvgIcon-root {
            margin-right: 0.62rem;
          }

          hr {
            color: #CCCCCC;
            border-top: var(--bs-border-width) solid;
            width: 100%;
            margin: 0.75rem 0rem;
          }
        }
        .MuiInputBase-input{ 
          &:focus-visible {
            outline: none;
            border: 0.125rem solid $InitioBlue;
          }
        }
      }

      .edited-by {
        @include body-small;
        @include flexbox();
        padding: 0.75rem 0.75rem 0rem;

        p {
          margin: 0rem;
        }
      }

      .expanded-bottom {
        .MuiButtonBase-root {
          @include outlined-button;
          width: 100%;
          margin-top: 1rem;

          .MuiSvgIcon-root {
            height: 1.125rem;
            width: 1.125rem;
          }
        }

        .MuiInputBase-root {
          padding: 0.625rem;
          margin-top: 1.5rem;
          border-radius: 0.75rem;
          font-size: 0.875rem;
          width: 17.5rem;
          height: 7.5rem;
          background-color: white;

          .MuiInputBase-input {
            margin-bottom: auto;
          }

          textarea::-webkit-scrollbar {
            display: none;
          }

          .MuiInputAdornment-root {
            margin-top: auto;
            margin-bottom: 0.625rem;

            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }

        .note-length {
          @include body-small;
          text-align: right;
          margin-top: 0.125rem;
          margin-right: 0.25rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    &:focus-visible {
      outline: none;
      border: 0.125rem solid $InitioBlue;
    }
  }
}

#name-tooltip {
  .MuiTooltip-tooltip {
    margin-bottom: 0.25rem;
    margin-left: 0.5rem;
    background-color: #00497D;
    .MuiTooltip-arrow {
      color: #00497D;
    }
  }
}
