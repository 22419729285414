@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.add-user-dialog,
.add-user-success-dialog {
  .MuiPaper-root {
    overflow: hidden;
    min-width: 880px;
    border-radius: 24px;
    padding: 32px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 12px;

      .MuiSvgIcon-root {
        color: $LightOutline;
        width: 38px;
        height: 38px;
      }

      h4 {
        @include headline-medium;
        margin-bottom: 0px;
      }
    }

    p {
      @include body-medium;
      &.dialog-text{
        margin-bottom: 40px;
      }
    }

    .dialog-box-buttons {
      @include flexbox(flex-end);
      gap: 10px;
      margin-top: 22px;

      .MuiButtonBase-root {
        &:first-child {
          @include outlined-button;
        }

        &:last-child {
          @include blue-contained-button;
          .loading-spinner {
            filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
            margin-right: 8px;
            height: 16px;
            width: 16px;
            @include spin-infinitely();
          }
        }
      }
    }
  }
}

.add-user-dialog {
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
}

.add-user-success-dialog {
  .box-header {
    h4 {
      margin: 0 auto;
    }
  }
  .success-msg-container {
    margin: 40px auto;
    @include flexbox();
    gap: 10px;
    padding: 24px 160px;
    background: $BackgroundGreen;
    border-radius: 4px;
    .check-icon {
      margin-top: 2px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      @include flexbox(center, center);
      background-color: $Success60;
      .MuiSvgIcon-root {
        fill: #fff;
        padding: 6px;
      }
    }
    p {
      @include body-medium;
      color: $Success50;
      margin: 0;
    }
  }
}
