@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ClientInputs {
  @include flexbox(flex-start, center, column);
  padding-bottom: 70px;
  .edit-btn-wrapper,
  .no-inputs-to-show,
  .tooltip-info-block,
  .input-block,
  .error-info-block,
  hr {
    width: 1312px;
  }
  .no-inputs-to-show {
    padding: 32px 0;
    @include flexbox(center, center);
    flex-direction: column;
    border: 1px solid $LightOutline;
    p {
      &:first-child {
        font-weight: 600;
      }
    }
  }
  .tooltip-info-block {
    @include flexbox(flex-start, center);
    gap: 8px;
    border: 1px solid $InitioBlue;
    padding: 6px 12px;
    .MuiSvgIcon-root {
      color: $InitioBlue;
    }
    .close-icon {
      margin-left: auto;
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
      }
    }
  }
  .no-inputs-to-show,
  .tooltip-info-block {
    background-color: #cfd9ea;
    margin-bottom: 16px;
    border-radius: 12px;
    p {
      @include body-small;
      margin: 0;
    }
  }
  .error-info-block {
    height: 56px;
    margin-bottom: 1rem;
    @include flexbox(flex-start, center);
    gap: 8px;
    border: 1px solid $LightError;
    border-radius: 12px;
    padding: 6px 12px;
    background-color: #ded7e1;
    p {
      @include body-small;
      margin: 0;
    }
    .MuiSvgIcon-root {
      color: $LightError;
    }
  }
  hr {
    color: $LightOutline;
    margin: 0;
  }
  .edit-btn-wrapper {
    @include flexbox(flex-end);
    .MuiButtonBase-root {
      @include text-only-button;
      height: 32px;
      margin-right: 12px;
      .MuiSvgIcon-root {
        height: 20px;
        width: 20px;
      }
      &.edit-btn {
        @include outlined-button;
      }
      &.submit-btn {
        @include blue-contained-button;
        margin-right: 0;
        &.no-pointers {
          pointer-events: none;
        }
      }
    }
  }
  .input-block {
    margin: 0.25rem 20px;
    border: 1px solid $Outline;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);

    .block-header {
      background-color: $MidnightSteel;
      height: 48px;
      @include flexbox(flex-start, center);
      h5 {
        @include label-large;
        color: $DarkOnSecondaryContainer;
        margin-left: 24px;
        margin-top: 4px;
        margin-bottom: 0px;
      }
      &.header-four {
        @include flexbox(flex-start, center);
        background-color: $MidnightSteel;
        span {
          @include label-medium;
          color: $DarkOnSecondaryContainer;
          margin-left: 16px;
          margin-top: 2px;
          width: 10.5rem;
          text-align: center;
          p {
            color: $Secondary70;
          }
        }
        &:first-child {
          width: 120.75%;
          padding-right: 1.5rem;
          padding-left: 13.5rem;
        }
        &.previous-val {
          width: auto;
        }
      }
      &.prev-val {
        background-color: #404659;
        &:first-child {
          padding-right: 1.5rem;
          padding-left: 10.5rem;
        }
      }
    }
    .holdings-titles {
      background-color: $MidnightSteel;
      height: 28px;
      @include flexbox(flex-start, center);
      border-top: 1px solid $Outline;
      border-bottom: 1px solid $Outline;
      span {
        @include label-medium;
        color: $DarkOnSecondaryContainer;
        margin-top: 2px;
        margin-left: 16px;
        margin-right: 16px;
        width: 220px;
        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }
        &:nth-child(2) {
          width: 260px;
        }
      }
    }
    .holdings-data {
      &:nth-child(odd) {
        background-color: $Background1;
      }
      &:nth-child(even) {
        background-color: $Container2;
      }
      height: 40px;
      @include flexbox(flex-start, center);
      .holding-data {
        @include body-small;
        margin-top: 2px;
        margin-left: 16px;
        margin-right: 16px;
        width: 220px;
        &:not(:nth-child(-n + 2)) {
          text-align: center;
        }
        &:nth-child(2) {
          width: 260px;
        }
      }
      .MuiFormControl-root {
        width: 240px;
        &:nth-child(2) {
          width: 360px;
        }
        &:nth-child(4) {
          width: 230px;
        }
        &:nth-child(5) {
          width: 200px;
        }
        .MuiInputBase-root {
          @include body-small;
          margin: 0.75rem 1rem;
          font-weight: 500;
          height: 24px;
          border-radius: 4px;
          background-color: #fff;
        }
      }
      .add-holding-btn {
        @include text-only-button;
        height: 24px;
      }
      .MuiIconButton-root {
        margin-left: auto;
      }
    }
    &.block-three {
      margin-bottom: 0rem;
      border-radius: 0.25rem 0.25rem 0rem 0rem;
      border-bottom: none;
    }
    &.block-four {
      margin-top: 0rem;
      margin-bottom: 0rem;
      border-radius: 0rem;
      border-top: none;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: $LightSurface;
      border-radius: 0rem 0rem 0.5rem 0.5rem;
    }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: $DarkOutline;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgb(0 0 0 / 0%);
      }
      .input-tiles {
        padding-left: 1rem;
        padding: 0.75rem 1rem 0.25rem 1rem;
        justify-content: none;

        &.fiscal-periods {
          display: flex;
          flex-wrap: wrap;
          gap: 0rem;
          padding: 0rem;
          .indicator-rows {
            display: flex;
            flex-direction: row;
            height: 3rem;
            padding-right: 1.35rem;
            span {
              @include body-small();
              display: flex;
              height: 3rem;
              align-items: center;
              padding-left: 1.25rem;
              width: 13.5rem;
            }
            .info-tile {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 1.75rem;
              height: 3rem;
              width: 10.5rem;
              .MuiFormControl-root {
                margin-bottom: 0.2 5rem;
                .MuiInputBase-root {
                  border: 1px solid $Outline;
                  fieldset {
                    display: none;
                  }
                  input {
                    text-align: right;
                    padding-right: 0.5rem
                  }
                }
              }
              p {
                @include body-small();
              }
            }
            &.even {
              background-color: $Background1;
            }
            &.odd {
              background-color: $Container2;
            }
          }
          &.prev-val {
            .indicator-rows {
              padding-right: 10.9rem;
              .info-tile {
                width: 11.5rem;
              }
            }
          }
        }
      }
    }
    &.block-five {
      border-radius: 0.5rem 0.5rem 0rem 0rem;
      box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
      margin-bottom: 0rem;
      .input-tiles {
        padding: 0.75rem 1.5rem;
        .info-tile {
          padding: 0.75rem 1.5rem 0.375rem;
          &:nth-child(2),
          &:last-child {
            white-space: nowrap;
          }
          &:nth-child(2) {
            padding-left: 1.25rem;
          }
        }
        &.prev-transaction {
          .info-tile {
            &:nth-child(2){
              position: relative;
              left: -45px;
              width: 345px;
            }
            &:nth-child(3){
              position: relative;
              left: -100px;
              width: 345px;
              padding-right: 0px;
            }
            &:nth-child(4) {
              position: relative;
              left: -80px;
              padding-left: 0px;
            }
            &:nth-child(6) {
              position: relative;
              left: -50px;
              width: 345px;
            }
            &:nth-child(7){
              position: relative;
              left: -40px;
              width: 345px;
            }
            &:nth-child(8) {
              position: relative;
              left: -100px;
              width: 247px;
            }
          }
        }
      }
    }
  }
  .info-header {
    
    width: 82rem;
    background-color: $LightSurfaceVariant;
    padding: 2rem 1.25rem;
    border: 0.06rem solid #ccc;
    border-bottom: none;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    overflow: hidden;
    h4 {
      @include label-large;
      margin-bottom: 0.25rem;
    }
    p {
      @include body-small
    }
  }
  hr {
    width: 81.37rem;
    margin: 0.75rem 0.63rem;
    padding: 0rem 3rem;
  }
  .prev-val {
    background-color: #404659;
    width: 81.9rem;
    padding: 1rem 0rem 0.75rem 1.5rem;
    h5 {
      @include label-large;
      color: $DarkOnSecondaryContainer;
    }
  }
  .input-tiles {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    gap: 1.5rem;
    background-color: $Container2;
    padding: 24px 48px;
    .half-width-tiles {
      @include flexbox;
      gap: 16px;
      .info-tile {
        width: 180px;
      }
    }
    &.uploads {
      @include flexbox;
      flex-wrap: wrap;
      .upload-column {
        @include flexbox(flex-start, flex-start, column);
        gap: 24px;
      }
      .info-tile {
        align-self: flex-start;
        justify-self: flex-start;
        min-width: 580px;
        padding: 8px 24px 16px;
      }
    }

    &.block-two {
      display: grid;
      & > :nth-child(-n + 4) {
        grid-row: 1;
      }
      & > :nth-child(n + 5) {
        grid-row: 2;
        grid-column: span 1;
      }
    }
  }
  .footnote-wrapper {
    background-color: #FAFAFD;
    padding: 1.38rem 1.14rem;
    width: 1310px;
    margin: 0rem -0.75rem;
    .MuiButtonBase-root {
      @include text-only-button;
      .MuiSvgIcon-root {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
    .prev-val-input-footnotes {
      @include flexbox(flex-start, flex-start, column);
      gap: 1.5rem;
      background-color: #EDF1FA;
      padding: 0.75rem;
      margin-top: 0.63rem;
      .input-wrappers {
        @include flexbox(flex-start, flex-start, row);
        gap: 1rem;
      }
      p {
        @include label-large;
        margin-bottom: 0.5rem;
      }
      .footnote-characters-left {
        @include body-small;
        text-align: right;
        padding-right: 0.35rem;
      }
      .MuiTextField-root {
        width: 38.3rem;
        .MuiInputBase-root {
          background-color: #FAFAFD;
          padding: 0.75rem;
          textarea {
            @include dropdown-scrollbar;
            overflow: auto !important;
            height: 5.25rem !important;
          }
        }
      }
    }
  }
}

.loading-comps {
  .MuiPaper-root {
    @include flexbox(center, center, column);
    gap: 24px;
    border-radius: 24px;
    padding: 32px;
    font-size: 24px;
    color: $LightOnSurfaceVariant;
    p {
      font-size: 16px;
    }
  }
}
