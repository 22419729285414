@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';
.uploadvaluationtimmer{
  .MuiPaper-root {
    position: absolute;
    // top: 7rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      margin: 1rem 0rem;
      width: 1rem;
      z-index: 10;
    }

    &::-webkit-scrollbar-track {
      margin: 1rem 0rem;
      background: transparent;
      z-index: 10;
    }

    &::-webkit-scrollbar-thumb {
      border: 0.313rem solid transparent;
      border-radius: 6.25rem;
      background-color: #49454f;
      background-clip: content-box;
      z-index: 10;
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
      z-index: 10;
    }

    min-width: 880px;
    min-height: 15.6rem;
    max-height: 47rem;
    padding: 3rem 3rem 1.5rem;
    border-radius: 1.5rem;
  }
}



.linear-progress {
    @include flexbox();
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    .linear-progress-header {
      @include flexbox(space-between, center);
      width: 100%;
      margin-bottom: 8px;
      span {
        @include body-medium;
        &:first-child {
          @include label-large;
        }
      }
    }
    .MuiLinearProgress-root {
      width: 100%;
      height: 16px;
      border-radius: 16px;
      outline: 1px solid $Outline;
      background: none;
      margin-bottom: 24px;
      .MuiLinearProgress-bar {
        background: linear-gradient(90deg, #00be8a 2.86%, #9aa4ff 52.88%, #707adb 99.84%);
      }
    }
  }
  .run-comps-tool-tip-header {
    background-color: $NeutralVariant95;
    padding: 24px;
    @include flexbox(flex-start, flex-start, column);
    margin-bottom: 24px;
    border-radius: 8px;
    p{
      @include body-medium;
      margin: 0;
      &:last-child {
        color: $LightOutline;
      }
    }
  }
  .steper_label_custom  svg{
    height: 25px ;
  }
  .MuiSvgIcon-root {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .css-8t49rw-MuiStepConnector-line{
    min-height: 12px !important;
  }