@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.add-comp-modal {
  .MuiPaper-root {
    overflow-y: auto;
    min-width: 1032px;
    border-radius: 24px;
    padding: 3rem 3rem 2.5rem 3rem;

    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 1rem 0rem;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: $DarkOutline;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-corner {
      background: rgb(0 0 0 / 0%);
    }
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .dialog-header {
      @include flexbox(flex-start, center);
      gap: 16px;

      .MuiSvgIcon-root {
        color: $BlueSteel;
        width: 6rem;
        height: 6rem;
      }

      .header-text {
        @include body-large;

        p {
          &:first-child {
            @include headline-large('Manrope');
          }
        }

        margin: 0;
      }
    }

    .warning-block {
      background-color: rgba(249, 222, 220, 0.3);
      padding: 40px 32px;
      margin-top: 24px;
      margin-bottom: 12px;
      border-radius: 4px;
      @include flexbox(flex-start, center);

      .MuiSvgIcon-root {
        color: $LightError;
        height: 36px;
        width: 36px;
      }

      p {
        @include headline-small;
        color: $LightError;
        margin-bottom: 0;
        margin-left: 24px;
      }
    }

    .add-companies-box {
      position: relative;
      background-color: $NeutralVariant95;
      border: 1px solid $Outline;
      border-radius: 8px;
      margin: 1.5rem 0rem 0.5rem;
      padding: 24px;

      p {
        @include body-medium;
        margin: 0;

        &:nth-child(2) {
          color: $LightOutline;
          font-style: italic;
          // margin-bottom: 24px;
        }
      }

      .add-company-row {
        @include flexbox(flex-start);

        .row-number {
          @include body-medium;
          margin-right: 16px;
          margin-top: 18px;
        }

        .MuiFormControl-root {
          .MuiInputBase-root {
            width: 486px;
            background-color: $Container2;
          }

          &.valid {
            .MuiOutlinedInput-notchedOutline {
              border-color: $Success;
            }

            .MuiSvgIcon-root {
              color: $Success;
            }
          }

          &.invalid {
            .MuiOutlinedInput-notchedOutline {
              border-color: $LightError;
            }

            .MuiFormHelperText-root,
            .MuiSvgIcon-root {
              color: $LightError;
            }
          }
        }

        .remove-field-btn {
          @include text-only-button;
          margin-left: 16px;
          margin-top: 6px;
          padding: 8px 8px;
        }
      }
    }

    .run-comps-tool-tip-header {
      background-color: $NeutralVariant95;
      padding: 24px;
      @include flexbox(flex-start, flex-start, column);
      margin-bottom: 24px;
      border-radius: 8px;
      p{
        @include body-medium;
        margin: 0;
        &:last-child {
          color: $LightOutline;
        }
      }
    }

    .linear-progress {
      @include flexbox();
      flex-direction: column;
      width: 680px;
      margin-bottom: 16px;
      .linear-progress-header {
        @include flexbox(space-between, center);
        width: 680px;
        margin-bottom: 8px;
        span {
          @include body-medium;
          &:first-child {
            @include label-large;
          }
        }
      }
      .MuiLinearProgress-root {
        width: 680px;
        height: 16px;
        border-radius: 16px;
        outline: 1px solid $Outline;
        background: none;
        margin-bottom: 24px;
        .MuiLinearProgress-bar {
          background: linear-gradient(90deg, #00be8a 2.86%, #9aa4ff 52.88%, #707adb 99.84%);
        }
      }
    }
    .individual-step {
      &:hover {
        cursor: default;
      }
    }
    .MuiStepConnector-line {
      min-height: 12px;
      border-left-width: 2px;
      border-color: #49454f;
    }
    .MuiStepper-root {
      margin-left: 24px
    }
    .MuiStepLabel-root {
      padding: 0;
      .MuiStepLabel-iconContainer {
        padding: 0;
        width: 24px;
        height: 24px;
        margin-left: 2px;
        margin-right: 16px;
        border-radius: 50%;
        @include spaced-between-flexbox(center);
        align-items: center;
      }
      &.completed {
        .MuiStepLabel-iconContainer {
          background-color: $Success;
          .MuiSvgIcon-root {
            fill: #ffffff;
            padding: 3px;
          }
        }
      }
      &.is-next,
      &.has-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: #49454f;
          border: 2px solid #49454f;
        }
      }
      &.not-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: #49454f;
          border: 2px solid #49454f;
          opacity: 0.7;
        }
        .MuiStepLabel-label {
          opacity: 0.7;
        }
      }
      .MuiStepLabel-label {
        position: relative;
        @include label-large;
        font-weight: 400;
      }
      &.is-next {
        .MuiStepLabel-label {
          font-weight: 600;
        }
      }
    }
    .MuiStepConnector-root {
      margin: 4px auto 4px 13px;
      .MuiStepConnector-line {
        min-height: 12px;
        width: 2px;
        margin-left: 0;
        background: #49454f;
        border: 1px solid #49454f;
        border-radius: 10px;
      }
    }

    .company-list-container {
      position: relative;
      width: 100%;
      height: 100%;

      .loading-overlay {
        @include flexbox(center, center);
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.5);

        .dots-circle-spinner {
          font-size: 64px;
          color: $InitioBlue;
        }
      }

      .company-list-header {
        @include flexbox(flex-start, center);
        gap: 18px;
        margin-bottom: 32px;

        div {
          margin-bottom: 8px;

          p {
            &:first-child {
              @include headline-large('Manrope');
              margin-bottom: 12px;
            }

            &:nth-child(2) {
              @include body-medium;
              margin: 0;
            }
          }
        }
      }

      ol {
        padding: 0rem;
        margin: 1.75rem 0rem;

        li {
          display: flex;
          margin-bottom: 4px;
          counter-increment: counterName;
          margin-bottom: 0rem;

          .MuiButtonBase-root {
            @include text-only-button;
            padding: 1rem;
            height: 2rem;
          }

        }

        li:before {
          content: counter(counterName)".";
          width: 2rem;
          text-align: right;
        }

        .comp-list-input {
          margin-left: 1rem;
          margin-bottom: 1rem;
          width: 780px;
          text-decoration: none;

          .MuiInputBase-root {
            text-decoration: none;
            border: none !important;
            box-shadow: none !important;
            padding: 0 !important;

            fieldset {
              border: none !important;
              box-shadow: none !important;
              padding: 0 !important;
              text-decoration: none;
            }
          }

          .dots-circle-spinner {
            font-size: 1.25rem;
            color: $InitioBlue;
            margin-right: .5rem;
          }

          .MuiFormHelperText-root {
            height: auto;
            margin: 0rem;
          }

          textarea {
            &::placeholder {
              font-style: italic;
            }
          }

          &.valid {
            .MuiOutlinedInput-notchedOutline {
              border-color: $Success;
            }

            .MuiSvgIcon-root {
              color: $Success;
            }
          }

          &.invalid {
            .MuiOutlinedInput-notchedOutline {
              border-color: $LightError;
            }

            .MuiFormHelperText-root,
            .MuiSvgIcon-root {
              color: $LightError;
            }
          }
        }
      }
    }

    .missing-value-warning {
      background-color: $LightErrorContainer;
      padding: 1.5rem;
      border-radius: 0.5rem;

      p {
        @include body-medium;
      }
    }

    .add-field-btn {
      @include outlined-button;
      margin-right: auto;
      height: 40px;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    .bottom-btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
      margin-top: 1.5rem;

      .cancel-btn {
        @include outlined-button;
        height: 40px;
        margin-right: 16px;
      }

      .add-companies-btn {
        @include blue-contained-button;
        height: 40px;
      }
    }

    .close-btn {
      @include blue-contained-button;
      height: 40px;
      margin-left: auto;
      margin-bottom: 24px;
    }

    .wait-longer-msg {
      @include flexbox();
      gap: 16px;
      background-color: $Tertiary95;
      @include body-large;
      color: $Tertiary30;
      padding: 16px 24px;

      .dots-circle-spinner {
        margin-top: 4px;
      }
    }
  }

  .industries-select {
    @include flexbox(flex-start, center);
    column-gap: 1rem;
    padding: 0rem 0rem 0rem 0.75rem;
    flex-wrap: wrap;

    .industry-dropdown {
      width: 17.04167rem;
      padding-top: 1rem;
      margin-right: 4px;
      .MuiInputBase-root {
        padding-right: 9px;
      }
      .MuiAutocomplete-popper {
        .MuiPaper-root {
          position: relative;
          min-width: 17rem !important;
          background-color: $Container2;
          padding: 0rem;
          .MuiAutocomplete-listbox {
            &::-webkit-scrollbar {
              width: 16px;
            }
            &::-webkit-scrollbar-track {
              background: transparent;
              margin: 1rem 0rem;
            }
            &::-webkit-scrollbar-thumb {
              border: 5px solid transparent;
              border-radius: 100px;
              background-color: $DarkOutline;
              background-clip: content-box;
            }
            &::-webkit-scrollbar-corner {
              background: rgb(0 0 0 / 0%);
            }
          }
        }
      }
    }
    .industry-add-btn {
      width: auto;
      color: $Primary40;
      align-items: start;
      margin-bottom: 0.25rem;
    }
  }
  &.industry {
    .MuiPaper-root {
      overflow-y: visible;
    }
  }
  &.running-comps {
    .MuiPaper-root {
      overflow: hidden;
      min-width: 780px;
    }
  }
}
