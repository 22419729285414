@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.ProjectCompanies {
  @include flexbox(center, center, column);

  .util-header {
    @include flexbox(center, center, column);
    min-width: 70.625rem;

    .header-spacer {
      width: 100%;
      left: 0rem;
      overflow: hidden;
      margin: 0.75rem, 0rem;
      // TODO implement later
      color: transparent;
    }

    .util-controls {
      @include flexbox(flex-end, center);
      width: 100%;
      margin-top: 1rem;
      margin-right: 1.12rem;

      .print-btn {
        border: 1px solid $Outline;
        margin-right: 0.38rem;
        padding: 0rem;
      }

      .undo-btn {
        border: 1px solid $Outline;
        border-radius: 0.25rem 0rem 0rem 0.25rem;
        border-right: .5px solid $Outline;
      }

      .redo-btn {
        border: 1px solid $Outline;
        border-left: none;
        border-radius: 0rem 0.25rem 0.25rem 0rem;
      }

      .MuiButtonBase-root {
        min-width: 2rem !important;
        height: 2rem;
      }

      .save-run-btns {
        @include label-large;
        color: $Primary40;
        text-transform: none;
        padding: 0.62rem 1rem 0.62rem 1rem;
        width: 8rem;

        &.save {
          margin-right: 0.75rem;
        }

        &.saving {
          @include flexbox(flex-start, center);
          margin-bottom: 0rem;
        }

        .MuiSvgIcon-root {
          color: $Primary40;
          margin-right: 0.5rem;
          width: 1.125rem;
          height: 1.125rem;
        }

        .loading {
          margin-right: 1.5rem;
        }

        &:focus-visible,
        &:active {
          outline: none;
          border: 0.125rem solid $InitioBlue;
        }
      }

      .MuiSvgIcon-root {
        color: #49454F;
        width: 1rem;
        height: 1rem;
      }
    }

  }

  .project-companies-header {
    @include flexbox();
    @include headline-5;
    gap: 1.5rem;
    width: 100%;

    p {
      margin: 0rem;
    }
  }

  .add-company-btn {
    @include flexbox(flex-end);
    width: 100%;
    padding-right: 5.94rem;
    gap: 1rem;
    .MuiButtonBase-root {
      @include tonal-contained-button();
      height: 2rem;
      .MuiSvgIcon-root {
        margin-right: 0.5rem;
        font-size: 1.125rem;
      }
    }
  }

  .companies-list-header {
    @include flexbox(space-between);
    width: 100%;

    h3 {
      @include headline-medium;

      .MuiSvgIcon-root {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  .sort-by-select {
    @include flexbox(flex-end, flex-end);
    width: 100%;
    padding-right: 5.74rem;

    .SortBy {
      margin-right: 1rem;
    }
  }

  .list-of-companies {
    @include flexbox(center);
    min-width: 70.625rem;
    padding-right: 5rem;

    .project-list-wrapper {
      @include flexbox(center, flex-start);
      width: 100%;
      min-height: 500px;
      padding-bottom: 24px;
      gap: 1.5rem;

      .wrapper-of-draggable {
        @include flexbox(flex-start, flex-start, column);
        width: 100%;
        height: max-content;
      }

      .dots-circle-spinner {
        @include flexbox(center, center);
        position: relative;
        // left: 48rem;
        top: 5rem;
        font-size: 4rem;
        color: $DarkOutline;
      }
    }
  }
}

#assign-all-menu {
  .MuiPaper-root {
    border-radius: 0.75rem;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
    margin-top: 0.75rem;
  }
  
  .MuiButtonBase-root {
    @include body-medium();
    &.active {
      background-color: rgba(66, 149, 243, 0.08);
      box-shadow: 4px 0 0 #4295f3 inset;
    }
  }
}

#all-user-menu {

  // box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
  .MuiTooltip-tooltip {
    @include flexbox(flex-start, flex-start, column);
    background-color: #FAFAFD !important;
    height: auto;
    margin-left: 1.2rem;
    border-radius: 0.75rem;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);

    .MuiTooltip-arrow {
      &::before {
        background-color: #FAFAFD;
      }
      font-size: 2.25rem; // Adjust the size as needed
    }

    .MuiButtonBase-root {
      @include body-medium();
      text-transform: none;
      @include flexbox(flex-start, flex-start);
      width: 100%;

      &:focus-visible {
        background: rgb(0 72 132 / 12%);
      }

      &:active {
        background: rgb(0 28 57 / 10%);

        box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
      }

      &.active {
        background-color: rgba(66, 149, 243, 0.08);
        box-shadow: 4px 0 0 #4295f3 inset;
      }
    }
  }
}
