@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.SortByDropDown {
  @include flexbox(center, center);
  @include label-large;
  font-size: 12px;
  white-space: nowrap;
  .sort-by-label {
    padding-right: 0.75rem;
  }
  .sort-by-btn {
    @include flexbox(center, center);
    padding: 0rem 0.5rem 0rem 0.75rem;
    .sort-by-btn-text {
      margin-right: 0.25rem;
      @include label-large;
      color: $Primary40;
      font-size: 12px;
      &:hover {
        background-color: transparent;
        color: black;
      }
    }
    &:focus-visible {
      // outline: none;
      border-radius: 0rem;
      border: 0.125rem solid $InitioBlue;
    }
    .MuiSvgIcon-root {
      height: 1.125rem;
      width: 1.125rem;
      margin-bottom: 0.1rem;
      margin-left: 0.35rem;
      margin-right: 0.35rem;
    }
    &:hover {
      background-color: transparent;
      color: black;
    }
  }
  .dropdown-btn {
    @include flexbox(center, center);
    @include text-only-button(none);

    padding: 0.62rem 0.5rem 0.62rem 0.8rem;
  
    .sort-by-btn-text {
      // margin-right: 8px;
      @include label-large;
      color: $Primary40;
      font-size: 12px;
    }
  
    .MuiSvgIcon-root {
      height: 1.125rem;
      width: 1.125rem;
      margin-bottom: 0.1rem;
      margin-left: 0.35rem;
      margin-right: 0.35rem;
    }
  
    &:hover {
      background-color: transparent;
      color: black;
    }
    &.is-open {
      background-color: rgb(0 28 57 / 10%);
      box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
    }
    &:focus-visible {
      outline: none;
      border: 0.125rem solid $InitioBlue;
    }
  }
}

#SortBy {
  .MuiPaper-root {
    max-height: 25rem;
    border-radius: 0.75rem;
    &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: #A4ABC0;
        background-clip: content-box;
      }
    .MuiList-root {
      .MuiButtonBase-root {
        &.active {
          background-color: rgba(66, 149, 243, 0.08);
          box-shadow: 4px 0 0 $InitioBlue inset;
        }
      }
    }
  }
}

#sort-by-tooltip {
  .MuiTooltip-tooltip {
    margin-bottom: 8px;
    background-color: $Primary30;
    @include label-medium;
    color: $White0;
    padding: 6px 8px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    .MuiTooltip-arrow {
      &::before {
        background-color: #00497d;
      }
    }
  }
}