@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.IntakeBlock {
  .submitted-data-tooltip {
    @include flexbox(flex-start, center);
    width: 1246px;
    border-top: 1px solid $InitioBlue;
    border-bottom: 1px solid $InitioBlue;
    padding: 8px 24px;
    height: 72px;
    background-color: #e4eefc;
    svg {
      height: 24px;
      width: 24px;
      margin-right: 12px;
    }
    p {
      @include body-medium;
      margin: 0;
    }
    .MuiButtonBase-root {
      @include button-to-link;
      text-decoration: underline;
    }
  }
  .intake-block-content-wrapper {
    @include flexbox();
    cursor: default !important;
    position: relative;
  }
  .left-stepper-container {
    width: 330px;
    background-color: $LightSurface;
    padding-top: 48px;
    padding-left: 38px;
    .MuiStepLabel-root {
      padding: 0;
      .MuiStepLabel-iconContainer {
        padding: 0;
        width: 24px;
        height: 24px;
        margin-left: 2px;
        margin-right: 16px;
        border-radius: 50%;
        @include flexbox(center, center);
      }
      &.completed {
        .MuiStepLabel-iconContainer {
          background-color: $InitioBlue;
          .MuiSvgIcon-root {
            fill: #ffffff;
            padding: 3px;
          }
        }
      }
      &.is-next,
      &.has-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: $InitioBlue;
          border: 2px solid $InitioBlue;
        }
      }
      &.not-started {
        .MuiStepLabel-iconContainer {
          @include body-small;
          line-height: 0;
          letter-spacing: 0;
          color: $LightOnSurfaceVariant;
          border: 2px solid $LightOnSurfaceVariant;
        }
      }
      .MuiStepLabel-label {
        @include flexbox(flex-start, center);
        @include label-large;
        font-weight: 400;
      }
      &.is-next {
        .MuiStepLabel-label {
          font-weight: 600;
        }
      }
    }
    .individual-step {
      .MuiIconButton-root {
        margin-left: 2px;
        height: 32px;
        width: 32px;
        background: none;
        border: none;
        @include flexbox(center, center);
        border-radius: 50%;
        &:hover {
          background-color: #e6f0f6;
          cursor: pointer;
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: #99c0db;
        }
      }
    }
    .MuiStepConnector-root {
      margin: 4px auto 4px 13px;
      .MuiStepConnector-line {
        min-height: 12px;
        width: 2px;
        margin-left: 0;
        background: $LightOnSurfaceVariant;
        border: 1px solid $LightOnSurfaceVariant;
        border-radius: 10px;
      }
    }
    .left-submit-btn {
      @include outlined-button;
      margin-top: 28px;
    }
  }
  .intake-blocks {
    @include flexbox(flex-start, center);
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-track {
      background: #e4eefb;
    }
    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-color: #49454f;
      background-clip: content-box;
    }
    .intake-block {
      @include white-container(876px);
      margin: 12px 12px;
      &.active-step {
        outline: 2px solid $InitioBlue;
      }
      h4 {
        @include headline-large('Manrope');
        margin: 0;
        .MuiSvgIcon-root {
          font-size: 3rem;
        }
      }
      p {
        @include body-medium;
        margin-bottom: 24px;
        &.no-access-contact-info {
          @include body-large();
        }
      }
      p {
        &:first-of-type {
          margin-top: 12px;
        }
        &:last-of-type {
          @include body-large;
          margin: 0;
        }
      }
      .block-header-text {
        font-size: 14px !important;
        margin-top: 12px !important;
      }
      .MuiTypography-root {
        @include body-medium;
      }
      .no-info-right-tooltip {
        @include flexbox(flex-start, center);
        border-radius: 12px;
        border: 1px solid $InitioBlue;
        padding: 8px 24px;
        background-color: rgba(0, 72, 132, 0.08);
        margin: 24px 0;
        .MuiSvgIcon-root {
          color: $InitioBlue;
          height: 24px;
          width: 24px;
          margin-right: 16px;
        }
        p {
          @include body-medium;
          margin: 0;
        }
      }
      .contact-us {
        background-color: $White30;
        border-radius: 12px;
        padding: 24px;
        h4 {
          .MuiSvgIcon-root {
            margin-right: 8px;
          }
        }
        p {
          @include body-large;
          &.contact-info {
            margin: 0;
          }
        }
      }
      .form-grid {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 24px;
        .MuiFormHelperText-root {
          @include body-small;
          height: 18px;
          margin-top: 2px;
          margin-left: 16px;
        }
      }
      .funding-extra-info {
        background-color: $NeutralVariant95;
        margin-left: -48px;
        margin-right: -48px;
        padding: 24px 48px;
        margin-top: 12px;
        .MuiInputBase-root {
          background-color: $Container2;
        }
      }
      .funding-upload-text {
        @include body-large;
        color: $LightOutline !important;
        span {
          font-weight: 700;
        }
      }
      .inputLabelNoShrink {
        margin-top: 1px;
        margin-left: 24px;
      }
      .company-performance,
      .share-class {
        margin-top: 24px;
        width: 100%;
      }
      .funding-radio {
        margin-top: 32px;
      }
      .ebitda-checkbox {
        @include flexbox(flex-start, center);
        margin: 24px auto 38px 8px;
        .MuiCheckbox-root {
          padding: 0;
          margin-right: 8px;
          .unchecked-icon {
            width: 16px;
            height: 16px;
            border: 1px solid #49454f;
            border-radius: 2px;
          }
          .checked-icon {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            background-color: $InitioBlue;
            @include flexbox(center, center);
            .MuiSvgIcon-root {
              color: #fafafd;
              padding: 6px;
            }
          }
        }
        .ebitda-checkbox-text {
          @include body-medium;
        }
      }
    }
    .submit-btn {
      margin: 12px 12px 12px auto;
      @include blue-contained-button;
    }
  }
  .resizer-btn {
    position: absolute;
    bottom: 0px;
    width: 1246px;
    height: 24px;
    background-color: transparent;
    border: none;
    cursor: url('../../images/icons/expand_icon.svg'), row-resize !important;
  }
}

.confirm-submit-dialog,
.submit-success-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;
    max-width: 686px;
    .close-icon {
      position: absolute;
      right: 37px;
      top: 37px;
      color: #999ba5;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      padding: 2px;
      &:hover {
        background-color: rgba(0, 97, 164, 0.1);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      &:active {
        background-color: rgba(0, 97, 164, 0.4);
      }
    }
    .box-header {
      @include flexbox(flex-start, center);
      gap: 10px;
      margin-bottom: 21px;
      .MuiSvgIcon-root {
        color: #79747e;
        height: 36px;
        width: 36px;
      }
      svg {
        height: 36px;
        width: 36px;
      }
      h4 {
        @include headline-medium;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
      }
      &.submit-success {
        .MuiSvgIcon-root {
          color: $Success50;
        }
        h4 {
          color: $Success50;
        }
      }
    }
    p {
      @include body-large;
    }
    .box-buttons {
      @include flexbox(flex-end);
      gap: 10px;
      margin-top: 42px;
      .cancel-btn {
        @include outlined-button;
      }
      .confirm-btn {
        @include blue-contained-button;
      }
    }
  }
}

.confirm-submit-dialog {
  p {
    @include body-large;
    font-weight: 500 !important;
  }
  li {
    @include body-large;
  }
}
