@import '../../../../styles/palette.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.CompTableRow {
  cursor: default;
  .comp-table-row-data {
    min-height: 32px;
    @include flexbox(flex-start, center);
    &.sub-company {
      margin-left: 336px;
      .right-scrollable,
      .fixed-left {
        overflow: hidden;
        border-bottom: 1px solid $MidnightSteel;
      }
      .comp-list {
        .table-cell {
          justify-content: center !important;
        }
      }
    }
    &.overlay-scroll {
      min-height: 16px;
      .fixed-left {
        border-bottom: none;
      }
      .right-scrollable {
        overflow-x: scroll;
        border-bottom: none;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border: 5px solid transparent;
          border-radius: 100px;
          background-color: $DarkOutline;
          background-clip: content-box;
        }
        &::-webkit-scrollbar-corner {
          background: rgb(0 0 0 / 0%);
        }
      }
      .table-cell {
        visibility: hidden;
        height: 0px !important;
        @-moz-document url-prefix() {
          height: 12px !important;
        }
      }
    }
    &.odd {
      .row-data-group {
        background-color: $Container2;
      }
    }
    &.even {
      .row-data-group {
        background-color: $Background1;
      }
    }
    &.row-hovered {
      .row-data-group {
        background-color: #e6f0f6;
      }
    }
    &.red-inactive-row {
      .row-data-group {
        background-color: #f9eeed;
      }
      &.row-hovered {
        .row-data-group {
          background-color: $LightErrorContainer;
        }
      }
    }
    &.yellow-multiples-disabled-row {
      .row-data-group {
        background-color: #fff7e9;
      }
      &.row-hovered {
        .row-data-group {
          background-color: $LightYellow;
        }
      }
    }
    .fixed-left {
      position: absolute;
      margin-left: -336px;
      box-shadow: 0px 1px 8px 1px rgba(0, 49, 93, 0.2);
      clip-path: inset(0px -8px 0px -0px);
      transform: skewY(0.001deg);
    }
    .right-scrollable {
      @include flexbox(flex-start, center);
    }
    .row-data-group {
      @include flexbox;
      .table-cell {
        height: 32px;
        @include body-small;
        @include flexbox(center, center);
        &.description-btn {
          .MuiButtonBase-root {
            @include button-to-link;
            border-radius: 0;
            height: 32px;
            width: 114px;
            &:focus-visible {
              border: 1px solid $InitioBlue;
            }
          }
        }
        &:last-child {
          margin-right: 16px;
        }
      }
      &.comp-list {
        .table-cell {
          &:nth-child(1) {
            min-width: 32px;
          }
          &:nth-child(2) {
            @include flexbox(flex-start, center);
            padding-left: 8px;
            min-width: 272px;
          }
          &:nth-child(3) {
            min-width: 32px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .outlier-flag-icon {
          color: $DarkOnSecondaryContainer;
          border-radius: 50%;
          height: 22px;
          width: 22px;
          margin: 0 4px;
          .MuiSvgIcon-root {
            height: 16px;
            width: 16px;
            color: $LightOnSurfaceVariant;
          }
          &:hover {
            background-color: #cfe1ee;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
          &.hidden {
            visibility: hidden;
          }
          &.is-outlier {
            visibility: visible;
            .MuiSvgIcon-root {
              color: $Primary40;
            }
          }
        }
      }
    }
    .MuiCheckbox-root {
      padding: 0;
      .unchecked-icon {
        width: 16px;
        height: 16px;
        border: 1px solid #49454f;
        border-radius: 2px;
      }
      .checked-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $InitioBlue;
        @include flexbox(center, center);
        .MuiSvgIcon-root {
          color: #fafafd;
          padding: 6px;
        }
      }
      .remove-check-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $LightError;
        @include flexbox(center, center);
        .MuiSvgIcon-root {
          color: #fafafd;
          padding: 6px;
        }
      }
      &.yellow {
        .remove-check-icon {
          background-color: $InitioYellow;
        }
      }
    }
  }
  .description-space {
    &.odd {
      background-color: $Background1;
    }
    &.even {
      background-color: $Container2;
    }
  }
  .comp-description {
    position: relative;
    &.odd {
      background-color: $Container2;
    }
    &.even {
      background-color: $Background1;
    }
    p {
      padding: 4px 16px;
      width: 791px;
      @include body-small;
      &.focused {
        background-color: $NeutralVariant95;
        border: 1px solid $InitioBlue;
      }
      &.hovered {
        background-color: $NeutralVariant95;
      }
    }
  }
  &.subject-company {
    border-bottom: 1px solid $MidnightSteel;
  }
}
