@import '../../../styles/palette.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.Comps {
  width: 1334px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70px;
  .no-comps-to-show {
    @include flexbox(center, center);
    width: 1334px;
    height: 104px;
    border: 1px solid $LightOutline;
    border-radius: 12px;
    @include label-medium;
    background-color: #cfdcec;
  }
  .missing-value-warning {
    background-color: $LightErrorContainer;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    p {
      @include body-medium;
    }
  }
  .loading-comps{
    @include flexbox(center, center);
    .dots-circle-spinner {
      font-size: 40px;
      margin-top: 24px;
      color: $BlueSteel;
    }
  }
  .update-button {
    @include flexbox(flex-end);
    margin-bottom: 24px;
    .MuiButtonBase-root {
      @include outlined-button;
      height: 32px;
      .loading-spinner {
        margin-right: 8px;
        $spinnerSize: 1rem;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
  .header-info-block,
  .industries-select-wrapper,
  .comps-wrapper {
    border-radius: 8px;
    background-color: $LightSurfaceVariant;
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
  }
  .header-info-block {
    padding: 32px 20px;
    margin-bottom: 1rem;
    h4 {
      margin: 0;
      .MuiSvgIcon-root {
        font-size: 48px;
        margin-right: 8px;
      }
      @include display-small('Manrope');
    }
    p,
    ul {
      @include body-small;
      color: $LightOutline;
      margin: 0;
      span {
        font-weight: 600;
      }
    }
    p {
      &:first-of-type {
        margin-top: 12px;
        width: 100%;
      }
      span {
        @include label-medium;
      }
      .MuiButtonBase-root {
        @include button-to-link;
        margin-bottom: 0.15rem;
        color: $Primary40;
        font-size: 0.75rem;
        font-style: normal;
        width: auto;
        font-weight: 400;
        margin-right: -0.2rem;
      }
    }
    .reroute-btn {
      @include button-to-link;
      margin-bottom: 0.15rem;
      color: $Primary40;
      font-size: 0.75rem;
      margin-left: 2rem;
      margin-top: 1rem;
    }
  }
  .volatility-inputs-wrapper {
    box-shadow: 0px 4px 8px 0px rgba(199, 203, 220, 0.85);
    border-radius: 8px;
    margin-bottom: 1rem;
    .volatility-inputs-header {
      @include flexbox(flex-start, center);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $LightSurfaceVariant;
      padding: 1rem;
      span {
        @include label-large;
        margin-right: 1rem;
        white-space: nowrap;
      }
      p {
        @include body-small;
        padding-right: 5rem;
      }
    }
    .volatility-inputs-content {
      @include flexbox;
      .volatility-input-wrapper {
        width: 40%;
        &:nth-child(2) {
          width: 60%;
        }
        padding: 24px 12px 24px 24px;
        background-color: $Container2;
        &:first-child {
          border-right: 1px solid $Outline;
          &.show-radius {
            border-bottom-left-radius: 8px;
          }
        }
        &:last-child {
          &.show-radius {
            border-bottom-right-radius: 8px;
            padding: 24px 12px 24px 24px;
          }
        }
        .volatility-input-top {
          @include flexbox(flex-start, center);
          .MuiFormControl-root {
            width: 30.1875rem;
            padding-right: 12px;
            .MuiFormLabel-root {
              color: $Primary40;
            }
            .MuiOutlinedInput-notchedOutline {
              border-color: $DarkOutline;
            }
            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $Primary40;
                padding-right: 10px;
              }
            }
          }
          .MuiButtonBase-root {
            @include text-only-button;
            padding-left: 12px;
            padding-right: 12px;
            margin-left: 12px;
          }
        }
        .volatility-input-footnote {
          background-color: $NeutralVariant95;
          padding: 1rem;
          border-radius: 8px;
          margin-top: 1rem;
          span {
            @include body-small;
            &:first-child {
              font-weight: 600;
            }
          }
          .MuiFormControl-root {
            margin-top: 1rem;
            width: 28rem;
            border-radius: 0.75rem;
            background-color: #fff;
            .MuiOutlinedInput-notchedOutline {
              height: auto;
              margin-top: -0.25rem;
            }
            textarea {
              @include dropdown-scrollbar;
            }
          }
          .footnote-characters-left {
            text-align: right;
            @include body-small;
            margin-right: 8px;
            margin-left: auto;
          }
        }
      }
    }
  }
  .industries-select-wrapper {
    h6 {
      @include label-large;
    }
    padding: 2rem 20px;
    margin-bottom: 1rem;
    .add-companies-view {
      @include flexbox(flex-start, flex-start, column);
      gap: 0.63rem;
      .add-companies-description {
        h6 {
          text-wrap: nowrap;
        }
      }
      .industry-dropdown {
        padding-top: 1rem;
        .MuiAutocomplete-root {
          .MuiInputBase-root {
            width: 380px;
            background-color: $Container2;
            padding-right: 1rem;
          }
        }
      }
      .submit-btns-container {
        @include flexbox(space-between, center);
        width: 100%;
        p {
          @include body-small;
          margin: 0;
          color: #49454f;
          font-weight: 400;
          font-size: 12px;
        }
        .submit-btn,
        .industries-btn {
          @include blue-contained-button;
          text-wrap: nowrap;
          width: 11rem;
          height: 34px;
          .MuiSvgIcon-root {
            height: 1.125rem;
            margin-right: 0.2rem;
          }
          &.no-pointers {
            pointer-events: none;
          }
        }
        .industries-btn {
          @include outlined-button();
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: #ccc;
      margin: 1rem 0 0;
    }
  }
  .comps-wrapper {
    padding: 1rem 12px;
    .comps-header {
      margin-bottom: 24px;
      .comps-header-text {
        @include flexbox(flex-start, center);
        gap: 8px;
        h6 {
          @include label-large;
          margin: 0;
          margin-bottom: 6px;
        }
        p {
          @include body-small;
          margin: 0;
          color: $LightOutline;
        }
        .MuiButtonBase-root,
        .outlier-status {
          @include text-only-button;
          height: 32px;
          margin-left: auto;
        }
        .outlier-status {
          pointer-events: none;
          .dots-circle-spinner {
            margin-right: 8px;
          }
        }
      }
      .industry-tooltip {
        @include flexbox(flex-start, center);
        border: 1px solid $InitioPurple;
        border-radius: 8px;
        @include body-small;
        padding: 6px 12px;
        .MuiSvgIcon-root {
          color: $InitioPurple;
        }
        .tooltip-link {
          @include button-to-link;
          @include body-small;
          color: $Primary40;
        }
      }
    }
    .multiples-table {
      width: 1310px;
      border: 1px solid $Outline;
      border-radius: 4px;
      overflow: hidden;
      margin-top: 8px;
      position: relative;
      .multiples-table-row {
        @include flexbox(flex-start, center);
        height: 32px;
        background-color: $Background1;
        border-bottom: 1px solid $Outline;
        &:last-child {
          border: none;
        }
        span {
          width: 86px;
          @include body-small;
          &:first-child {
            @include label-medium;
            width: 980px;
            margin-left: 1rem;
          }
        }
      }
    }
  }
  .update-comp-btn {
    .-circle-spinner {
      margin-right: 8px;
    }
    &.no-pointers {
      pointer-events: none;
    }
  }
  .MuiSnackbar-root {
    p {
      margin: 0;
    }
  }

  .helper-text {
    margin: 6px auto;
    @include body-small;
    color: $LightOutline;
    @include flexbox(flex-start, center);
    span,
    .MuiButtonBase-root {
      @include body-small;
    }
    span {
      color: $LightOutline;
    }
    .MuiButtonBase-root {
      @include button-to-link;
      color: $Primary40;
    }
  }
}

.iqr-tooltip {
  .MuiTooltip-tooltip {
    min-width: 353px;
    .iqr-link {
      color: white;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
