@import "../../../styles/mixins.scss";
@import "../../../styles/palette.scss";
@import "../../../styles/typography.scss";

.CapStructure {
  @include flexbox(flex-start, center, column);
  padding-bottom: 70px;
  .TableElement {
    width: 95%;

  }

  .inline-tables {
    display: flex;
    gap: 1.5rem;
    width: 95%;
    .two-third-width,
    .third-width {
      width: 100%;
    }
    > div:nth-of-type(2n) {
      width: 67%;
    }
    > div:nth-of-type(2n + 1) {
      width: 33%;
    }
  }

  .CalcTable {
    width: 1340px;

    .MuiTableHead-root {
      .MuiTableRow-root {
        background: rgba(199, 203, 220, 0.5);
        border: none;

        &:first-child {
          .MuiTableCell-root {
            justify-content: center !important;
            align-items: center;
            height: 32px;
            border: none;
            border-top: none;
            border-bottom: 1px solid $White0;

            &:not(:first-child) {
              border-left: 1px solid $White0;
            }
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          justify-content: flex-start !important;
          padding-left: 16px;
        }
      }
    }
  }

  .cap-summary {
    @include flexbox(center, center);
    gap: 1.5rem;
    margin-top: 3rem;

    div {
      @include flexbox(center, center, column);
      @include label-large;
      width: 19.375rem;
      height: 5.5rem;
      border-radius: 0.5rem;
      background: $Primary75;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
      color: $DarkOnSecondaryContainer;
      p {
        @include label-medium;
        text-align: center;
        color: $DarkOnSecondaryContainer;
      }
    }
  }

  .breakpoint-analysis {
    @include flexbox(flex-start, flex-start, column);
    margin-top: 1.5rem;
    width: 96%;

    .info-container {
      @include flexbox(flex-start, center);
      margin: 1.5rem 0rem;
      gap: 1.5rem;
      width: 100%;
      p {
        @include body-small;
      }

      .info {
        @include label-large;
        @include flexbox(center, center, column);
        border-radius: 8px;
        background-color: $BlueSteel;
        width: 6.25rem;
        height: 3.375rem;
        text-align: center;
        color: $White0;

        p {
          @include title-small;
          color: $NeutralBackground;
          font-size: 9px;
        }
      }

      p {
        .link-to-820inputs {
          @include button-to-link;
          @include body-small;
          color: $Primary40;
          height: 0rem;
        }
      }
    }

    .MuiButtonBase-root {
      @include tonal-contained-button;
      background-color: #D0D9EE;
      height: 2rem;
    }

    &.content {
      width: 100%;
      padding: 1rem;
      overflow-x: hidden;

      p {
        @include label-large;
        margin-bottom: 0.75rem;
        width: auto;
      }
    }

    &.backdrop {
      width: 100%;
      border-radius: 0.5rem;
      background-color: $LightSurfaceVariant;
      box-shadow: 0px 4px 8px 0px rgba(0, 49, 93, 0.20);
    }

    &.active {
      @include flexbox(flex-start, flex-start, column);
      width: 95%;

      .MuiButtonBase-root {
        margin-right: auto;
        margin-left: 0rem;
        &.refresh-btn {
          margin-left: auto;
          margin-right: 0rem;
        }
      }
    }
  }
}

@media (min-width: 1600px) and (max-width: 1878px) {
  .CapStructure {
    .TableElement {
      width: 95%;
      .MuiPaper-root {
        overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 16px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border: 5px solid transparent;
          border-radius: 100px;
          background-color: $DarkOutline;
          background-clip: content-box;
          
        }
        &::-webkit-scrollbar-corner {
          background: rgb(0 0 0 / 0%);
        }

      }
      &.third-width,
      &.two-third-width {
        width: 100%;
        .MuiPaper-root {
          overflow: hidden;
        }
      }
    }
    .inline-tables {
      width: 98%;
      padding: 0rem 1.8rem;
      > div:nth-of-type(2n) {
        width: 67%;
      }
      > div:nth-of-type(2n + 1) {
        width: 33%;
      }
    }
    .breakpoint-analysis {
      width: 95% !important;
      &.backdrop,
      &.content {
        width: 100% !important;
      }
      &.active {
      width: 95% !important;
    }
    }
  }
}

@media (min-width: 1878px) {
  .CapStructure {
    .TableElement {
      width: 1784px;
      .MuiPaper-root {
        overflow-x: hidden;
      }
      &.third-width,
      .two-third-width {
        width: 100%;
        .MuiPaper-root {
          overflow: hidden;
        }
      }
    }
    .inline-tables {
      width: 1784px;
      > div:nth-of-type(2n) {
        width: 67%;
      }
      > div:nth-of-type(2n + 1) {
        width: 33%;
      }
    }
    .breakpoint-analysis {
      width: 1784px !important;
      &.backdrop,
      &.content {
        width: 100% !important;
      }
      &.active {
        width: 1784px !important;
      }
    }
  }
}
