@import '../styles/palette.scss';
@import '../styles/mixins.scss';
@import '../styles/typography.scss';

.UploadValuationToProject {
  .MuiPaper-root {
    position: absolute;
    top: 7rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      margin: 1rem 0rem;
      width: 1rem;
      z-index: 10;
    }

    &::-webkit-scrollbar-track {
      margin: 1rem 0rem;
      background: transparent;
      z-index: 10;
    }

    &::-webkit-scrollbar-thumb {
      border: 0.313rem solid transparent;
      border-radius: 6.25rem;
      background-color: #49454f;
      background-clip: content-box;
      z-index: 10;
    }

    &::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
      z-index: 10;
    }

    min-width: 880px;
    min-height: 15.6rem;
    max-height: 47rem;
    padding: 3rem 3rem 1.5rem;
    border-radius: 1.5rem;
  }

  .add-companies-block {
    @include flexbox(flex-start, flex-start, column);
    margin: 0rem;

    .header-intro-wrapper {
      @include flexbox(flex-start, center, row);

      .MuiSvgIcon-root {
        margin-right: 1.5rem;
        height: 4rem;
        width: 4rem;
      }

      .header-text-wrapper {
        h4 {
          @include headline-large('Manrope');
        }
        p {
          margin: 0.5rem 0rem;
          @include body-large;
        }
      }

      .MuiButtonBase-root {
        position: absolute;
        right: 3rem;
        top: 3rem;
        // top: -2rem;

        .MuiSvgIcon-root {
          margin-right: 0rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    hr {
      margin: 1.5rem 0rem;
      width: 100%;
    }

    .add-companies-btn {
      @include outlined-button;
      display: flex;
      align-self: flex-end;
      align-content: flex-end;
      height: 2.5rem;
      text-transform: capitalize !important;
      border-color: #1976d2 !important;
      &.disabled {
        background-color: #CCCCCC;
        border: none;
        color: #FFFFFF;
      }
    }
    .add-company-btn:disabled{
      background-color: #CCCCCC;
      color: #FFFFFF;
    }
  }
}
