@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';


.CompletedProjects {
  @include flexbox(center, center, column);

  .complete-projects-wrapper {
    @include flexbox(center, center, column);
    width: 87rem;

    .home-btn {
      @include headline-medium;
      position: relative;
      margin-right: auto;
      left: 1rem;
      top: 1rem;
      text-align: center;
      text-transform: none;
      height: auto;
      border: 0.125rem solid transparent;
      font-weight: 500;

      .MuiSvgIcon-root {
        margin-bottom: 0.20rem;
        margin-right: 0.38rem;
        font-weight: 500;
      }

      &:hover {
        color: $Primary40;
        background-color: transparent;
      }

      &:focus-visible {
        border: 0.125rem solid $InitioBlue;
      }
    }

    h2 {
      @include headline-medium();
      color: rgba(73, 69, 79, 1);
    }

    .archived-header {
      @include flexbox();
      width: 70.3rem;
      height: 6rem;

      h2 {
        margin: 1rem 0rem 0rem 1.25rem;

        .MuiSvgIcon-root {
          margin-right: 0.5rem;
        }
      }
    }

    .archived-container {
      @include backdrop-container(70.3rem);
      background-image: url('../../images/background.png');
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 110vh;

      h2 {
        margin-left: -1.75rem;
        margin-top: -0.854rem;
        color: rgba(73, 69, 79, 1);
        opacity: 1 !important;
      }

      .projects-gird {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
        margin-top: 2.1rem;
        opacity: 1;

        .ProjectStatusCard {
          margin-left: 0rem;
          opacity: 1;
        }
      }
    }
    .no-completed {
      margin-top: 8rem;
      @include white-container(70rem);
      height: 15rem;
    }
  }
}