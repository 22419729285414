@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.SplashPage {
  @include flexbox(center, center, column);
  margin-top: 2.188rem;
  .splash-container {
    @include white-container(55rem);
    @include flexbox(center, center, column);
    padding-top: 0rem;
    p {
      @include body-large;
      margin-top: 0.625rem;
      text-align: center;
    }
    .MuiButtonBase-root {
      @include text-only-button(underline);
    }
  }
}