@import '../../../styles/mixins.scss';
@import '../../../styles/palette.scss';
@import '../../../styles/typography.scss';

.UploadTile {
  @include flexbox;
  gap: 24px;
  .drag-and-drop-area {
    position: relative;
    border: 1px dashed $NeutralVariant70;
    border-radius: 24px;
    @include flexbox(center, center);
    flex-direction: column;
    width: 190px;
    height: 140px;
    background-color: #dae4f1;
    .file-upload-loading-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 24px;
      z-index: 999;
      background: #fafafd;
      opacity: 0.8;
      @include flexbox(center, center);
      .loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
    .browse-or-drop {
      @include flexbox(center, center, column);
      span {
        @include body-large;
        cursor: default;
      }
      .MuiSvgIcon-root {
        color: $LightOnSurfaceVariant;
        height: 40px;
        width: 40px;
      }
      .MuiButtonBase-root {
        @include outlined-button;
        &.file-dragging {
          pointer-events: none !important;
        }
      }
    }
    &.file-dragging {
      border: 1px dashed #0967a7;
      background-color: #f3f5fa;
      .MuiButtonBase-root {
        pointer-events: none !important;
      }
    }
    .file-style {
      display: none;
    }
  }

  .file {
    @include flexbox(flex-start, center);
    .file-name {
      @include body-medium;
      @include flexbox(flex-start, center);
      gap: 4px;
      .dots-circle-spinner {
        margin-right: 4px;
      }
    }
    .file-and-progress {
      @include flexbox;
      flex-direction: column;
      .MuiLinearProgress-root {
        width: 200px;
        margin: 4px 0px;
        height: 4px;
        border-radius: 2px;
        background-color: $Outline;
        .MuiLinearProgress-bar {
          background-color: $InitioBlue;
        }
      }
    }
    &.completed {
      .MuiLinearProgress-root {
        .MuiLinearProgress-bar {
          background-color: $Success;
        }
      }
      .file-name {
        .check-icon {
          color: $Success;
        }
      }
    }
    .MuiButtonBase-root {
      @include body-medium;
      @include button-to-link;
      color: $Primary40;
      @include flexbox(flex-start, center);
      gap: 4px;
    }
  }

  .uploaded-files {
    @include flexbox();
    flex-direction: column;
    gap: 8px;
    span {
      @include body-medium;
    }
    a {
      @include body-medium;
      color: $Primary40;
      &:hover {
        opacity: 0.8;
      }
    }
    .MuiIconButton-root {
      margin-left: 8px;
      padding: 2px;
    }
  }

  .upload-button {
    @include button-to-link;
    height: 24px;
  }
}
