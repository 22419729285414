@import '../../styles/palette.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.GeneralInfo {
  @include white-container(1312px);
  padding: 32px 24px;
  margin: 32px auto;
  position: relative;
  .info-dropdown {
    margin: 28px auto;
    .header-row {
      @include flexbox(flex-start, center);
      gap: 10px;
      height: 64px;
      padding: 16px 36px;
      margin: 8px -24px 12px -24px;
      &:hover {
        background: rgba(0, 97, 164, 0.1);
      }
      &:active {
        background: rgba(0, 97, 164, 0.2);
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
      h5 {
        @include headline-medium;
        margin: 0 !important;
        color: $NeutralVariant30;
      }
      .edit-in-wizard-btn,
      .chevron-btn {
        .MuiSvgIcon-root {
          color: #49454f;
        }
        @include flexbox(center, center);
        border: none;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background: none;
        &:hover {
          background-color: rgba(0, 97, 164, 0.1) !important;
        }
        &:focus-visible {
          outline: 2px solid $InitioBlue;
        }
        &:active {
          background-color: rgba(0, 97, 164, 0.4) !important;
        }
      }
      :last-child {
        margin-left: auto;
      }
    }
    .info-block {
      @include flexbox(space-between, flex-start, column);
      background-color: $NeutralVariant95;
      border-radius: 8px;
      padding: 24px 24px;
      gap: 0.62rem;
      hr {
        margin: 12px -24px 24px;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .info-block-label {
        @include label-medium;
        margin: 0;
        span {
          @include label-medium;
          font-weight: 400;
        }
        &.capIQ {
          margin: 0.75rem 0rem 0.25rem;
        }
        &.report {
          margin-top: 1rem;
          span {
            @include label-medium;
          }
        }
      }
      .assignees-avatars-row {
        @include flexbox;
        gap: 0.62rem;
        .assignees-avatars-block {
          min-width: 80px;
        }
        .MuiAvatar-root {
          width: 1.75rem;
          height: 1.75rem;
          font-size: 12px;
          line-height: 0;
          margin-top: 0.25rem;
          background-color: $NeutralContainer;
          .MuiSvgIcon-root {
            color: $DarkOutline;
            background-color: $NeutralContainer;
            font-size: 1.5rem;
          }
          &:hover {
            border: 1px solid $DarkOnSecondaryContainer;
            outline: 2px solid #00488429;
            cursor: pointer;
          }
          &:focus-visible {
            border: 2px solid $DarkOnSecondaryContainer;
            outline: 2px solid $InitioBlue;
          }
        }
        span {
          color: $Primary40;
          font-family: Roboto;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1.25rem;
          letter-spacing: 0.03125rem;
          .sort-by-btn {
            padding-left: 0.25rem;
          }
          &:hover {
            color: black
          }
        }
      }
      &.company-description {
        .capIQ-checkbox {
          color: #49454F;
          margin-left: -0.6rem;
          &.Mui-checked {
            color: $Outline10;
          }
        }
        .descipt-copy {
          @include body-medium;
        }
        span {
          @include body-small();
        }
        .MuiInputBase-root {
          padding: 10px;
          border-radius: 12px;
          font-size: 14px;
          width: 1208px;
          .MuiInputBase-input {
            @include body-medium;
            margin-bottom: auto;
          }
          textarea::-webkit-scrollbar {
            display: none;
            margin-top: 6px;
          }
          .MuiInputAdornment-root {
            margin-top: auto;
            margin-bottom: 10px;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            top: -10px;
          }
          .MuiInputBase-input {
            @include body-medium();
            &.Mui-disabled {
              -webkit-text-fill-color: $LightOnSurfaceVariant !important;
            }
          }
        }

        .custom-desc-copy {
          @include flexbox(center, center);
          @include body-small();
          margin: 1rem 0rem 1.25rem 0rem;
          p {
            padding-right: 3rem;
          }
          span {
            @include label-medium;
          }
          .btns-container {
            white-space: nowrap;
          }
        }
        .edit-company-desc {
          background-color: white;
          border-radius: .75rem;

          .MuiInputBase-root {
            padding: 10px;
            border-radius: 12px;
            font-size: 14px;
            width: 1208px;
            min-height: 5.35rem;
          }
        }
        .custom-desc-block {
          @include flexbox(space-between, center);
          width: 100%;
        }
        .capIQ-desc {
          @include body-medium();
          background-color: rgba(239, 240, 243, 0.60);
        }
        .no-capIQ {
          background-color: white;
          border-radius: .75rem;
          .MuiInputBase-root {
            padding: 10px;
            border-radius: 12px;
            font-size: 14px;
            width: 1208px;
            min-height: 5.35rem;
          }
        }
        .header-btn-container {
          @include flexbox(space-between, flex-start, column);
          width: 100%;
        }
        #non-textfield-description {
          @include body-medium();
          padding: 0.6rem;
          background-color: rgba(239, 240, 243, 0.60);
          border-radius: 0.75rem;
          border: 1px solid $DarkOutline;
          width: 1208px;
          min-height: 5.35rem;
        }
      }
    }

    .data-tiles {
      @include flexbox(space-between);
      width: 100%;
      &.first-row {
        margin-bottom: 24px;
      }
      .data-tile {
        .data-name {
          @include label-medium;
          color: $NeutralVariant30;
          display: block;
          opacity: 0.8;
          margin-bottom: 4px;
        }
        .data {
          @include body-large;
          color: $NeutralVariant30;
          display: block;
          word-break: break-word;
        }
      }
      &.paymentInfo {
        @include flexbox();
      }
    }
    .input-tiles {
      display: grid;
      grid-template-columns: repeat(2, min-content);
      gap: 40px;
      background-color: $Container2;
      padding: 24px 48px;
      width: 100%;
      background-color: $NeutralVariant95;
      .info-tile {
        width: 570px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        .upload-tile {
          .upload-button {
            @include button-to-link();
            color: $Primary40;
            margin-top: 0.5rem;
          }
          .uploaded-files {
            .file {
              @include flexbox();
              .MuiButtonBase-root {
                @include button-to-link;
                margin-left: 0.5rem;
                font-size: 1rem !important;
                color: $Primary40;
                font-weight: 400;
                .MuiSvgIcon-root {
                  margin-right: 0.25rem;
                }
              }
            }
          }
        }
      }
    }
  }
  hr {
    margin: auto 180px;
  }
}
